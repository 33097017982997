import React, { useState, useEffect } from 'react'
import Butacas, { Butaca } from './Butacas'
import { Button, Modal, Input } from 'antd'
import './Recintos.css'
import loadashClone from 'lodash.clonedeep';
import Theme from 'Theme'
import AsignarNumero from './AsignarNumero'
import Http from 'Http';

export const Numeros = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '100'];
export const Letras = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const ButacaOb = (Fil, Col, Selected = false) => {
  return {
    Fila: Fil,
    Columna: Col,
    Selected,
  }
}
const ButacaFOb = ({ Fil, Col, Selected = false, Muerta, Butaca, Seccion }) => {
  return {
    Fila: Fil,
    Columna: Col,
    Selected,
    Muerta,
    Butaca,
    Seccion
  }
}

let ButacaBase = ButacaOb(0, 0);

export default function Numerar({ area = null }) {
  const [Area, setArea] = useState({
    Filas: 11,
    Columnas: 11,
    Butacas: [
      ButacaOb(0, 0), ButacaOb(0, 1), ButacaOb(0, 2), ButacaOb(0, 3), ButacaOb(0, 4), ButacaOb(0, 5), ButacaOb(0, 6), ButacaOb(0, 7), ButacaOb(0, 8), ButacaOb(0, 9), ButacaOb(0, 10),
      ButacaOb(1, 0), ButacaOb(1, 1), ButacaOb(1, 2), ButacaOb(1, 3), ButacaOb(1, 4), ButacaOb(1, 5), ButacaOb(1, 6), ButacaOb(1, 7), ButacaOb(1, 8), ButacaOb(1, 9), ButacaOb(1, 10),
      ButacaOb(2, 0), ButacaOb(2, 1), ButacaOb(2, 2), ButacaOb(2, 3), ButacaOb(2, 4), ButacaOb(2, 5), ButacaOb(2, 6), ButacaOb(2, 7), ButacaOb(2, 8), ButacaOb(2, 9), ButacaOb(2, 10),
      ButacaOb(3, 0), ButacaOb(3, 1), ButacaOb(3, 2), ButacaOb(3, 3), ButacaOb(3, 4), ButacaOb(3, 5), ButacaOb(3, 6), ButacaOb(3, 7), ButacaOb(3, 8), ButacaOb(3, 9), ButacaOb(3, 10),
      ButacaOb(4, 0), ButacaOb(4, 1), ButacaOb(4, 2), ButacaOb(4, 3), ButacaOb(4, 4), ButacaOb(4, 5), ButacaOb(4, 6), ButacaOb(4, 7), ButacaOb(4, 8), ButacaOb(4, 9), ButacaOb(4, 10),
      ButacaOb(5, 0), ButacaOb(5, 1), ButacaOb(5, 2), ButacaOb(5, 3), ButacaOb(5, 4), ButacaOb(5, 5), ButacaOb(5, 6), ButacaOb(5, 7), ButacaOb(5, 8), ButacaOb(5, 9), ButacaOb(5, 10),
      ButacaOb(6, 0), ButacaOb(6, 1), ButacaOb(6, 2), ButacaOb(6, 3), ButacaOb(6, 4), ButacaOb(6, 5), ButacaOb(6, 6), ButacaOb(6, 7), ButacaOb(6, 8), ButacaOb(6, 9), ButacaOb(6, 10),
      ButacaOb(7, 0), ButacaOb(7, 1), ButacaOb(7, 2), ButacaOb(7, 3), ButacaOb(7, 4), ButacaOb(7, 5), ButacaOb(7, 6), ButacaOb(7, 7), ButacaOb(7, 8), ButacaOb(7, 9), ButacaOb(7, 10),
      ButacaOb(8, 0), ButacaOb(8, 1), ButacaOb(8, 2), ButacaOb(8, 3), ButacaOb(8, 4), ButacaOb(8, 5), ButacaOb(8, 6), ButacaOb(8, 7), ButacaOb(8, 8), ButacaOb(8, 9), ButacaOb(8, 10),
      ButacaOb(9, 0), ButacaOb(9, 1), ButacaOb(9, 2), ButacaOb(9, 3), ButacaOb(9, 4), ButacaOb(9, 5), ButacaOb(9, 6), ButacaOb(9, 7), ButacaOb(9, 8), ButacaOb(9, 9), ButacaOb(9, 10),
      ButacaOb(10, 0), ButacaOb(10, 1), ButacaOb(10, 2), ButacaOb(10, 3), ButacaOb(10, 4), ButacaOb(10, 5), ButacaOb(10, 6), ButacaOb(10, 7), ButacaOb(10, 8), ButacaOb(10, 9), ButacaOb(10, 10),
    ]
  })
  const [Asignar, setAsignar] = useState({
    visible: false,
    tipo: 0,
  })
  const [Seccion, setSeccion] = useState({ visible: false, seccion: '' })
  const [Saving, setSaving] = useState(false)

  useEffect(() => {
    loadNumeracion();
    // eslint-disable-next-line
  }, [])

  const loadNumeracion = async () => {
    if (!area) return;
    try {
      let Res = await Http.get(`area/${area}/numeracion`);
      console.log(Res.data)
      if (!Res.error) {
        if (Res.data.butacas.length === 0) return;
        setArea({
          Filas: Res.data.filas,
          Columnas: Res.data.columnas,
          Butacas: Res.data.butacas,
        })
      }
    } catch (error) {

    }
  }

  const modFila = (val = 1) => {
    setArea(prevArea => {
      prevArea.Filas += parseInt(val);
      modButacas(prevArea.Filas, prevArea.Columnas);
      return { ...prevArea };
    })
  }

  const modButacas = (filas, columnas) => {
    setArea(prevArea => {
      let newButacas = []
      for (let f = 0; f < filas; f++)
        for (let c = 0; c < columnas; c++) {
          let ButIdx = prevArea.Butacas.findIndex(butaca => butaca.Columna === c && butaca.Fila === f);
          if (ButIdx < 0)
            newButacas.push(ButacaFOb({ Fil: f, Col: c }))
          else
            newButacas.push(ButacaFOb({ ...prevArea.Butacas[ButIdx], Fil: f, Col: c }));
        }
      console.log(prevArea.Butacas)
      return { ...prevArea, Butacas: newButacas }
    })
  }

  const modColumna = (val = 1) => {
    setArea(prevArea => {
      prevArea.Columnas += parseInt(val);
      modButacas(prevArea.Filas, prevArea.Columnas);
      return { ...prevArea };
    })
  }

  const clickButaca = (Bi) => {
    setArea(prevArea => {
      ButacaBase = { ...prevArea.Butacas[Bi] };
      prevArea.Butacas[Bi].Selected = !ButacaBase.Selected;
      return { ...prevArea }
    })
  }
  const mouseDownButaca = (Bi) => {
    ButacaBase = { ...Area.Butacas[Bi] };
    // console.log(`Base cambiada a ${ButacaBase.Fila}, ${ButacaBase.Columna}`)
  }
  const mouseEnterButaca = (Bi) => {
    if (document.butacasMouseDown) {
      let ButacaEnter = Area.Butacas[Bi];
      // console.log(`MouseDown y entró a butaca ${ButacaEnter.Fila},${ButacaEnter.Columna}`)
      setArea(prevArea => {
        prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
          // console.log(`Base es ${ButacaBase.Fila},${ButacaBase.Columna}`)
          if (Butaca.Fila < ButacaBase.Fila || Butaca.Columna < ButacaBase.Columna) return Butaca;
          if (Butaca.Fila > ButacaEnter.Fila || Butaca.Columna > ButacaEnter.Columna) return Butaca;
          // console.log(`Base es ${ButacaBase.Fila},${ButacaBase.Columna} (${ButacaBase.Selected}), Se cambia ${Butaca.Fila},${Butaca.Columna}`)
          Butaca.Selected = !ButacaBase.Selected;
          return Butaca;
        })
        return { ...prevArea }
      })
    }
  }

  const areaMuerta = () => {
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (Butaca.Selected) {
          Butaca.Muerta = true;
          Butaca.Selected = false;
          Butaca.Butaca = null;
        }
        return Butaca;
      })
      return { ...prevArea }
    })
  }

  const desmarcar = () => {
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (Butaca.Selected) {
          Butaca.Muerta = false;
          Butaca.Butaca = null;
          Butaca.Selected = false;
        }
        return Butaca;
      })
      return { ...prevArea }
    })
  }

  const deseleccionar = () => {
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (Butaca.Selected) {
          Butaca.Selected = false;
        }
        return Butaca;
      })
      return { ...prevArea }
    })
  }

  const setButaca = (butaca, val, tipo) => {
    if (!butaca) return val;
    let dos = butaca.split('-');
    dos[tipo] = val;
    return dos.join('-');
  }

  const onAsignar = (tipo = 1) => {
    setAsignar({ ...Asignar, visible: true, tipo });
  }
  const asignar = (values) => {
    setAsignar({ ...Asignar, visible: false });
    console.log(values, Asignar.tipo)
    let Pivote = Area.Butacas.find(butaca => butaca.Selected);
    let ButacasTR = loadashClone(Area.Butacas);
    let Pivotent = ButacasTR.reverse().find(butaca => butaca.Selected);
    console.log(Pivote, Pivotent)

    // return;
    if (Asignar.tipo === 1) {
      if (values.personalizado) {
        setArea(prevArea => {
          prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
            if (!Butaca.Selected) return Butaca;
            Butaca.Selected = false;
            if (Butaca.Muerta) return Butaca;
            Butaca.Butaca = setButaca(Butaca.Butaca, values.personalizado, 0);
            return Butaca;
          })
          return { ...prevArea }
        })
      }
      else {
        setArea(prevArea => {
          let Caracteres = values.tipo === '1' ? Numeros : Letras;
          let CI = parseInt(values.desde);
          prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
            if (!Butaca.Selected) return Butaca;
            Butaca.Selected = false;
            if (Butaca.Muerta) return Butaca;
            if (values.direccion === '1')
              Butaca.Butaca = setButaca(Butaca.Butaca, Caracteres[CI + Butaca.Fila - Pivote.Fila], 0);
            else if (values.direccion === '2')
              Butaca.Butaca = setButaca(Butaca.Butaca, Caracteres[CI + Pivotent.Fila - Butaca.Fila], 0);
            // Butaca.Butaca = Caracteres[CI + Pivotent.Fila - Butaca.Fila];
            Butaca.Muerta = false;
            return Butaca;
          })
          return { ...prevArea }
        })
      }
    }


    else if (Asignar.tipo === 2) {
      if (values.personalizado) {
        setArea(prevArea => {
          prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
            if (!Butaca.Selected) return Butaca;
            Butaca.Selected = false;
            if (Butaca.Muerta) return Butaca;
            Butaca.Butaca = setButaca(Butaca.Butaca, values.personalizado, 1);
            return Butaca;
          })
          return { ...prevArea }
        })
      }
      else {
        setArea(prevArea => {
          let Caracteres = values.tipo === '1' ? Numeros : Letras;
          let CI = parseInt(values.desde);
          prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
            if (!Butaca.Selected) return Butaca;
            Butaca.Selected = false;
            if (Butaca.Muerta) return Butaca;
            if (values.direccion === '1')
              Butaca.Butaca = setButaca(Butaca.Butaca, Caracteres[CI + Butaca.Fila - Pivote.Fila], 1);
            else if (values.direccion === '2')
              Butaca.Butaca = setButaca(Butaca.Butaca, Caracteres[CI + Pivotent.Fila - Butaca.Fila], 1);
            else if (values.direccion === '3')
              Butaca.Butaca = setButaca(Butaca.Butaca, Caracteres[CI + Butaca.Columna - Pivote.Columna], 1);
            else if (values.direccion === '4')
              Butaca.Butaca = setButaca(Butaca.Butaca, Caracteres[CI + Pivotent.Columna - Butaca.Columna], 1);
            // Butaca.Butaca = Caracteres[CI + Pivotent.Fila - Butaca.Fila];
            Butaca.Muerta = false;
            return Butaca;
          })
          return { ...prevArea }
        })
      }
    }
  }

  const onSeccion = () => {
    setSeccion({ ...Seccion, visible: true })
  }
  const seccion = () => {
    setSeccion({ ...Seccion, visible: false })
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (!Butaca.Selected) return Butaca;
        Butaca.Selected = false;
        Butaca.Seccion = Seccion.seccion;
        return Butaca;
      })
      return { ...prevArea };
    })
  }

  const onSave = () => {
    Modal.confirm({
      title: '¿Guardar la numeración del área?',
      icon: 'exclamation',
      onOk() {
        console.log('OK');
        save();
      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }
  const save = async () => {
    setSaving(true)
    try {
      let Res = await Http.post(`area/${area}/numeracion/save`, { ...Area });
      console.log(Res.data);
      if (!Res.error) {

        setSaving(false)
      }
      else {
        setSaving(false)

      }
    } catch (error) {

      setSaving(false)
    }
  }
  // console.log(Area.Butacas)

  const onKeyPress = (event) => {
    console.log(event.key)
  }


  return (
    <div className='numerar' style={{ paddingTop: 40 }} onKeyPress={onKeyPress}>

      <div style={{ textAlign: 'right' }}>
        <Button type='primary' size='large' onClick={() => onSave()} loading={Saving} >Guardar</Button>
      </div>
      <div className='panel' style={{ borderColor: Theme.primary }}>
        <div>
          <Button icon='plus-circle' shape='round' onClick={() => modFila()}>Fila</Button>
          <Button icon='minus-circle' shape='round' onClick={() => modFila(-1)}>Fila</Button>
        </div>
        <div>
          <Button icon='plus-circle' shape='round' onClick={() => modColumna()}>Columna</Button>
          <Button icon='minus-circle' shape='round' onClick={() => modColumna(-1)}>Columna</Button>
        </div>
        <div>
          <span>{Area.Filas} x {Area.Columnas}</span>
        </div>
      </div>
      <div className='panel' style={{ borderColor: Theme.primary }}>
        <Button shape='round' onClick={() => onSeccion()}>Sección</Button>
        <Button shape='round' onClick={() => onAsignar()}>Fila</Button>
        <Button shape='round' onClick={() => onAsignar(2)}>Butaca</Button>
        <Button shape='round' onClick={() => areaMuerta()}>Área muerta</Button>
        <Button shape='round' onClick={() => desmarcar()}>Desmarcar</Button>
        <Button shape='round' onClick={() => deseleccionar()}>Deseleccionar</Button>
      </div>

      <div style={{ marginTop: 20 }}>
        <Butacas width={(Area.Columnas * 30) + (Area.Columnas * 2)}>
          {
            Area.Butacas.map((ButacaInf, Bi) => (
              <Butaca
                key={`Butaca${ButacaInf.Fila},${ButacaInf.Columna}`}
                fila={ButacaInf.Fila}
                columna={ButacaInf.Columna}
                selected={ButacaInf.Selected}
                muerta={ButacaInf.Muerta}
                butaca={ButacaInf.Butaca}
                seccion={ButacaInf.Seccion}
                onClick={(e) => clickButaca(Bi)}
                onMouseDown={(e) => mouseDownButaca(Bi)}
                onMouseEnter={(e) => mouseEnterButaca(Bi)}
              />
            ))
          }
        </Butacas>
      </div>

      <Modal
        destroyOnClose={true}
        visible={Asignar.visible}
        onOk={() => setAsignar({ ...Asignar, visible: false })}
        onCancel={() => setAsignar({ ...Asignar, visible: false })}
        footer={null}
      >
        <AsignarNumero
          onSave={asignar}
          tipo={Asignar.tipo}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        visible={Seccion.visible}
        onOk={() => setSeccion({ ...Seccion, visible: false })}
        onCancel={() => setSeccion({ ...Seccion, visible: false })}
        footer={null}
      >
        <Input style={{ width: '100%' }} placeholder='Sección' value={Seccion.seccion} onChange={(e) => setSeccion({ ...Seccion, seccion: e.target.value })} />
        <Button onClick={() => seccion()}>Aceptar</Button>
      </Modal>

    </div>
  )
}
