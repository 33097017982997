import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Empresas() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('nombre', 'Nombre'),
      Table.Field('idpropietario', 'Propietario'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('empresa', 'nombre', 'idempresa')
    )
  )
  
  return (
    <div>
      <h2>Empresas</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
