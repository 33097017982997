import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import BackButton from 'components/BackButton/BackButton';

export default function Ciudad() {
  
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('idciudad'),
      Form.Field('text', 'ciudad', 'Ciudad', 8, { required: true }),
      Form.Field('text', 'nombrecorto', 'Nombre corto', 4, { required: true }),
    ], ['Guardar'], 'ciudad')
  );
  
  useEffect(() => {
    
  }, []);
  
  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
  }
  
  return (
    <div>
      <BackButton />
      <h2>Ciudad</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
