import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'
import { Modal } from 'antd'
import Distribucion from './Distribucion'

export default function Disponibilidades() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('idpropietario', 'Propietario'),
      Table.Field('descripcion', 'Recurso'),
      Table.Field('fecha', 'Fecha'),
      Table.Field('horainicial', 'Hora inicial'),
      Table.Field('horafinal', 'Hora final'),
    ], [
      ...Table.DefaultActions(),
      Table.Action('detalles', 'Detalles', (row) => detallesClick(row))
    ],
      Table.Config('recurso-disponibilidad', 'fecha', 'iddisponibilidad')
    )
  )
  const [ModalDistribucion, setModalDistribucion] = useState({ visible: false, id: null })

  const detallesClick = (row) => {
    console.log(row)
    setModalDistribucion({ ...ModalDistribucion, id: row.iddisponibilidad, visible: true });
  }

  return (
    <div>
      <h2>Disponibilidades</h2>
      <TableComponent
        data={TableData}
      />

      <Modal
        title={'Distribución'}
        destroyOnClose={true}
        visible={ModalDistribucion.visible}
        onCancel={() => setModalDistribucion({...ModalDistribucion, visible: false})}
        onOk={() => setModalDistribucion({...ModalDistribucion, visible: false})}
        width='50%'
      >
        <Distribucion
          disponibilidad={ModalDistribucion.id}
        />
      </Modal>
    </div>
  )
}
