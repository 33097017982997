import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import { Numeros, Letras } from './Numerar';

export default function AsignarNumero({ onSave = null, tipo = 1 }) {
  let direccionOpciones = [
    { Value: '1', Label: 'Hacia abajo' },
    { Value: '2', Label: 'Hacia arriba' }
  ]
  if (tipo === 2)
    direccionOpciones.push(
      { Value: '3', Label: 'Hacia la derecha' },
      { Value: '4', Label: 'Hacia la izquierda' },
    )
  let desdeOpciones = Numeros.map((Numero, i) => {
    return { Value: i, Label: Numero };
  })

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('select', 'tipo', 'Tipo', 12, { required: true }, [
        { Value: '1', Label: '1, 2, 3, ..., N' },
        { Value: '2', Label: 'A, B, C, ..., N' }
      ], '1', (val) => tipoChange(val)),
      Form.Field('select', 'direccion', 'Dirección', 12, { required: true }, direccionOpciones, '1'),
      Form.Field('select', 'desde', 'Desde', 12, { required: false }, desdeOpciones, 0),
      Form.Field('text', 'personalizado', 'Definir con', 12, { required: false }),
    ], ['Guardar'], null, false)
  );

  useEffect(() => {

  }, []);

  const tipoChange = (val, fields) => {
    let Opciones = [];
    if (val === '1') {
      Numeros.forEach((Numero, i) => {
        Opciones.push({ Value: i, Label: Numero });
      })
      setFormData({ ...Form.FieldOptions({ ...FormData }, 'desde', Opciones, 'Value', 'Label') });
    }
    else if (val === '2') {
      Letras.forEach((Letra, i) => {
        Opciones.push({ Value: i, Label: Letra });
      })
      setFormData({ ...Form.FieldOptions({ ...FormData }, 'desde', Opciones, 'Value', 'Label') });
    }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    onSave(Values)
  }

  return (
    <div>
      <h2>Asignar</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
