import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';

export default function Personalizacion() {
  const [loading, setLoading] = useState(false);
  const [FormInfo, setFormInfo] = useState(
    Form.Group([
      Form.Field('text', 'nombre_sistema', 'Nombre del sistema', 12, { required: true }),
      Form.Field('color', 'color1', 'Color primario', 6, { required: false }),
      Form.Field('color', 'color2', 'Color secundario', 6, { required: false }),
      Form.Field('file', 'logo', 'Logo', 12, { required: false }),
    ], ['Guardar'], 'propietario', false)
  );

  useEffect(() => {
    loadPreds();
    // eslint-disable-next-line
  }, []);

  const loadPreds = async () => {
    try {
      let Res = await Http.get(`propietario/personalizacion`);
      // console.log(Res.data)
      if (!Res.error) {
        const { data } = Res;
        console.log(data)
        setFormInfo({ ...Form.PatchPreds({ ...FormInfo }, { ...data }) })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = (Values, Button) => {
    // console.log(`[Submit] Botón ${Button}, valores: `, Values);
    saveProp(Values);
  }

  const saveProp = async (vals) => {
    console.log(vals);
    setLoading(true);
    let postData = new FormData();
    postData.append('color1', rgbToHex(vals.color1));
    postData.append('color2', rgbToHex(vals.color2));
    postData.append('nombre_sistema', vals.nombre_sistema);
    postData.append('logo', vals.logo);
    try {
      let res = await Http.post(`propietario/personalizacion`, postData);
      console.log(res);
      if(!res.error) 
        window.location.reload()
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }

  function rgbToHex({ r, g, b }) {
    const componentToHex = (c) => {
      var hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }
    return componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  return (
    <div>
      <h2>Personalización</h2>
      <FormComponent
        data={FormInfo}
        onSubmit={onSubmit}
        onValueChanged={setFormInfo}
        loading={loading}
      />
    </div>
  )
}
