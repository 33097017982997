import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';

let Params = [];

export default function Evento() {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('select', 'idpropietario', 'Propietario', 6, { required: true }),
      Form.Field('select', 'idevento', 'Evento', 6, { required: true }),
      Form.Field('date', 'fecha', 'Fecha', 4, { required: true }),
      Form.Field('time', 'hora', 'Hora', 4, { required: true }),
      Form.Field('text', 'numevento', 'No. Evento', 4, { required: true }),
      Form.Field('date', 'fec_ini_vta', 'Vigencia inicio', 6, { required: true }),
      Form.Field('date', 'fec_fin_vta', 'Vigencia fin', 6, { required: true }),
      Form.Field('autocomplete', 'idproductor', 'Productor', 3, { required: true }, [], undefined, null, false, (val) => AC_Fetch(val)),
      Form.Field('select', 'idrecinto', 'Recinto', 3, { required: true }),
      Form.Field('select', 'categoria', 'Categoría', 3, { required: true }),
      Form.Field('selectsearch', 'ciudad', 'Ciudad', 3, { required: true }),
      Form.Field('textarea', 'comentario', 'Comentario 1', 12, { required: true }),
      Form.Field('textarea', 'comentario2', 'Comentario 2', 12, { required: true }),
      Form.Field('checkbox', 'esserie', 'Es serie', 3, { required: false }),
      Form.Field('date', 'fec_ini_ser', 'Inicio de serie', 3, { required: false }),
      Form.Field('date', 'fec_fin_ser', 'Fin de serie', 3, { required: false }),
      Form.Field('number', 'canteventos', 'Cantidad de eventos', 3, { required: true }),
      // AGREGAR FECHA DE INICIO Y FIN DE SERIE
      Form.Field('textarea', 'condiciones', 'Condiciones', 12, { required: true }),
      Form.Field('selectm', 'FormasEnvio', 'Formas de entrega', 6, { required: false }),
      Form.Field('selectm', 'FormasPago', 'Formas de pago', 6, { required: true }),
      Form.Field('checkbox', 'activo', 'Activo', 4, { required: false }),
      Form.Field('checkbox', 'cambiolocalidad', 'Permitir cambios de localidad', 4, { required: false }),
      Form.Field('number', 'comision', 'Comisión', 4, { required: false }),
      Form.Field('date', 'puerta_ini', 'Apertura de puertas', 6, { required: false }),
      Form.Field('date', 'puerta_fin', 'Cierre de puertas', 6, { required: false }),
      Form.Field('select', 'vendidopor', 'Vendido por', 6, { required: true }),
      Form.Field('select', 'clavecorta', 'Usuario alta', 6, { required: true }),
      Form.Section('Internet'),
      Form.Field('checkbox', 'pinternet', 'Publicar', 3, { required: false }),
      Form.Field('checkbox', 'vinternet', 'Vender', 3, { required: false }),
      Form.Field('radio', 'cinternet', 'Estado', 6, { required: true }, [
        { Value: 0, Label: 'No disponible' },
        { Value: 1, Label: 'Proximamente' },
        { Value: 2, Label: 'Bol. x taquilla' },
        { Value: 3, Label: 'Agotado' },
        { Value: 4, Label: 'Cerrado' },
      ]),
      Form.Field('number', 'mincantventa', 'Cantidad mínima', 6, { required: true }),
      Form.Field('number', 'maxcantventa', 'Cantidad máxima', 6, { required: true }),
      Form.Field('date', 'fec_ini_vta_web', 'Vigencia inicio', 6, { required: false }),
      Form.Field('date', 'fec_fin_vta_web', 'Vigencia fin', 6, { required: false }),

    ], ['Guardar'], 'model', false)
  );

  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, []);

  const AC_Fetch = (value) => {
    // console.log(value)
    Params = Form.FillParam(Params, 'prod', value);
    fillForm();
  }

  const fillForm = async () => {
    // console.log(Params)
    try {
      let Res = await Http.get('calendario/form', Params);
      // console.log(Res.data.productores)
      if (!Res.error) {
        // let NewForm = 
        if (Res.data.idusuario)
          setFormData(Form.Patch({ ...FormData }, {
            clavecorta: `${Res.data.idusuario}`,
            idpropietario: 'AREMA',
            idevento: '1'
          }))
        setFormData(
          Form.FieldOptionsGroup({ ...FormData }, [
            Form.FOG_Field('idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'),
            Form.FOG_Field('idproductor', Res.data.productores, 'idproductor', 'nombre'),
            Form.FOG_Field('categoria', Res.data.categorias, 'categoria', 'categoria'),
            Form.FOG_Field('ciudad', Res.data.ciudades, 'ciudad', 'ciudad'),
            Form.FOG_Field('idevento', Res.data.eventos, 'idevento', 'nombre'),
            Form.FOG_Field('idrecinto', Res.data.recintos, 'idrecinto', 'nombre'),
            Form.FOG_Field('vendidopor', Res.data.propietarios, 'idpropietario', 'descripcion'),
            Form.FOG_Field('clavecorta', Res.data.usuarios, 'clavecorta', 'usuario'),
            Form.FOG_Field('FormasPago', Res.data.formas_pago, 'FORMAPAGO', 'DESCRIPCION'),
            Form.FOG_Field('FormasEnvio', Res.data.formas_envio, 'IDENVIO', 'DESCRIPCION'),
          ])
        )

      }
    } catch (error) {

    }
  }

  const onSubmit = async (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    try {
      let Res = await Http.post('calendario/save/evento', { ...Values });
      console.log(Res)
    } catch (error) {

    }
  }

  // const guardarCalendario = () => {

  // }

  return (
    <div>
      <h2>Evento</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
