import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Global from 'Global';

export default function Serie({ onNext, data = {}, fill = {} }) {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('checkbox', 'esserie', 'Es serie', 4, { required: false }, [], false, (val, a) => esSerieChange(val, a)),
      // Form.Field('checkbox', 'esserie', 'Es serie', 4, { required: false }, [], false),
      Form.Field('number', 'canteventos', 'Cantidad de eventos', 8, { required: false }),
      Form.Field('date', 'fec_ini_ser', 'Inicio de serie', 6, { required: false }),
      Form.Field('date', 'fec_fin_ser', 'Fin de serie', 6, { required: false }),
    ], ['Siguiente'], null, false)
  );

  useEffect(() => {
    if (data) {
      // console.log('data', data)
      setFormData(Form.Patch({ ...FormData }, { ...data }))
    }
    propSettings();
    // eslint-disable-next-line
  }, []);

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({
        ...Form.FieldModGroup(FormData, [
          Form.FMG_Field("esserie", "Disabled", true),
          Form.FMG_Field("canteventos", "Disabled", true),
          Form.FMG_Field("fec_ini_ser", "Disabled", true),
          Form.FMG_Field("fec_fin_ser", "Disabled", true),
        ])
      });
    }
  }

  let prevEsSerie = false;
  const esSerieChange = (value, fvalue = false) => {
    if (prevEsSerie !== fvalue) {
      // console.log(value, fvalue)
      // if (fvalue) {
      //   setFormData(Form.FieldModGroup({ ...FormData }, [
      //     Form.FMG_Field('canteventos', 'Validators', { required: true }),
      //     Form.FMG_Field('fec_ini_ser', 'Validators', { required: true }),
      //     Form.FMG_Field('fec_fin_ser', 'Validators', { required: true }),
      //   ]))
      // }
      prevEsSerie = fvalue;
    }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    onNext(Values)
  }

  return (
    <div>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
