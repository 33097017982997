import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import BackButton from 'components/BackButton/BackButton';

export default function Propietario() {

  const [FormData, setFormData] = useState(
    Form.Group([
      // Form.ID('idpropietario'),
      Form.Field('text', 'idpropietario', 'Propietario', 12, { required: true, maxLength: 5 }),
      Form.Field('text', 'descripcion', 'Descripción', 12, { required: true }),
    ], ['Guardar'], 'propietario')
  );

  useEffect(() => {

  }, []);

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
  }

  return (
    <div>
      <BackButton />
      <h2>Propietario</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={(data) => setFormData(data)}
      />
    </div>
  )
}


