import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';

export default function DatoGeneral() {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('select', 'idpropietario', 'Propietario', 12, { required: true }),
      Form.Field('text', 'compania', 'Compañía', 6, { required: true }),
      Form.Field('text', 'comercial', 'Nombre comercial', 6, { required: true }),
      Form.Field('text', 'gerente', 'Gerente', 6, { required: true }),
      Form.Field('text', 'rfc', 'R.F.C.', 6, { required: true }),
      Form.Field('text', 'direccion', 'Dirección', 6, { required: true }),
      Form.Field('text', 'lugar', 'Lugar', 6, { required: true }),
      Form.Field('text', 'ubicacion', 'Ubicación', 12, { required: true }),
      Form.Field('number', 'dolar', 'Dolar', 4, { required: true }),
      Form.Field('number', 'iva', 'IVA', 4, { required: true }),
      Form.Field('number', 'pcxc', '% CxC', 4, { required: true }),
      Form.Field('number', 'maxcantventa', 'Cantidad máxima', 4, { required: true }),
      Form.Field('selectm', 'FormasEnvio', 'Formas de entrega', 8, { required: true }),
    ], ['Guardar'], 'spec')
  );

  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, []);

  const fillForm = async () => {
    try {
      let Res = await Http.get('spec/form');
      if (!Res.error) {
        setFormData(
          Form.FieldOptionsGroup({ ...FormData }, [
            Form.FOG_Field('idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'),
            Form.FOG_Field('FormasEnvio', Res.data.formas_envio, 'IDENVIO', 'DESCRIPCION'),
          ])
        )
      }
    } catch (error) {

    }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
  }

  return (
    <div>
      <h2>Datos generales</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
