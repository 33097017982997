import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import { useHistory, useParams } from "react-router-dom";
import Http from 'Http';
import { message } from 'antd';
import Global from 'Global';

export default function Recurso() {
  const Params = useParams();
  const History = useHistory();
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: true }, []),
      // Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: true }, [], Global.BIOMEX === "1" ? "BPMEX" : undefined),
      Form.Field('text', 'descripcion', 'Descripción', 12, { required: true }),
      Form.Section('Recursos múltiples'),
      Form.Field('time', 'duracion', 'Duración', 12, { required: true }),
    ], ['Guardar'], 'recurso', false)
  );

  useEffect(() => {
    fillForm();
    propSettings();
    // eslint-disable-next-line
  }, []);

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({ ...Form.FieldMod(FormData, "idpropietario", "Value", Global.PROP) })
    }
  }

  const fillForm = async () => {
    try {
      let Res = await Http.get('recurso/form');
      console.log(Res)
      if (!Res.error) {
        setFormData(
          Form.FieldOptionsGroup({ ...FormData }, [
            Form.FOG_Field('idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'),
          ])
        )
      }
    } catch (error) {

    }
  }

  const onSubmit = async (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    Values.id = Params.id;
    try {
      let Res = await Http.post('recurso/save/recurso', Values);
      console.log(Res)
      if (!Res.error) {
        message.info('Recurso guardado');
        History.goBack();
      }
    } catch (error) {
      console.log(error)
      message.warn('Error al guardar');
    }
  }

  return (
    <div>
      <h2>Recurso</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
