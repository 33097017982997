import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Usuarios() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('idpropietario', 'Propietario'),
      Table.Field('usuario', 'Usuario'),
      Table.Field('clavecorta', 'Clave corta'),
      Table.Field('puesto', 'Puesto'),
      Table.Field('nivel', 'Nivel'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('usuario', 'usuario', 'idusuario')
    )
  )
  
  return (
    <div>
      <h2>Usuarios</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
