import React, { useEffect, useState } from 'react'
import './Apertura.css'
import Theme from 'Theme'
import Http from 'Http'
import { Spin, Button } from 'antd'
import { Form, FormComponent } from 'components/Form'
import moment from 'moment'

export default function AperturaT2() {
  const [Estado, setEstado] = useState(null)
  const [CerrarFormData, setCerrarFormData] = useState(
    Form.Group([
      Form.Field('datetime', 'fecha_cierre', 'Fecha de cierre', 6, { required: true }, null, moment()),
      Form.Field('datetime', 'fecha_abre', 'Fecha de apertura', 6, { required: true }),
      Form.Field('text', 'mensaje', 'Mensaje', 12, { required: true }),
    ], ['Cerrar sistema'], null, false)
  )
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    cargarEstado()
  }, [])

  const cargarEstado = async () => {
    setEstado(null)
    try {
      let Res = await Http.get(`apertura/estado`);
      console.log(Res)
      if (!Res.error) {
        setEstado({ ...Res.data })
      }
    } catch (error) {

    }
  }

  const onCerrarSistema = async (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    try {
      let Res = await Http.post('apertura/cerrar', { ...Values });
      if (!Res.error) {
        cargarEstado();
      }
    } catch (error) {
      console.log(error)
    }
  }

  const abrirSistema = async () => {
    setLoading(true);
    try {
      let Res = await Http.post('apertura/abrir', {});
      if (!Res.error) {
        cargarEstado();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  return (
    <div className='apertura'>
      {
        !Estado && (
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <Spin size='large' />
          </div>
        )
      }
      {
        Estado && (
          <React.Fragment>
            <Bloque title='Estado'>
              <h2>{Estado.cerrado ? 'CERRADO' : 'ABIERTO'}</h2>
            </Bloque>
            {
              !Estado.cerrado && (
                <div>
                  <Bloque title='Cerrar sistema'>
                    <FormComponent
                      data={CerrarFormData}
                      onSubmit={onCerrarSistema}
                      onValueChanged={setCerrarFormData}
                    />
                  </Bloque>
                </div>
              )
            }
            {
              Estado.cerrado && (
                <div>
                  <Bloque title='Datos'>
                    <BloqueDato
                      title='Cierre'
                      dato={Estado.fecha_cierre}
                    />
                    <BloqueDato
                      title='Apertura'
                      dato={Estado.fecha_abre}
                    />
                    <BloqueDato
                      title='Mensaje'
                      dato={Estado.mensaje}
                    />
                  </Bloque>
                  <Button type='primary' style={{ width: '100%' }} onClick={() => abrirSistema()} disabled={Loading}>ABRIR SISTEMA</Button>
                </div>
              )
            }
          </React.Fragment>
        )
      }
    </div>
  )
}

const Bloque = ({ title, children }) => {
  return (
    <div className='estado-con' style={{ borderColor: Theme.primary }}>
      <span className='estado-tit' style={{ color: Theme.primary }}>{title}</span>
      <div className='estado-dat' style={{ display: 'flex', justifyContent: 'space-around' }}>
        {children}
      </div>
    </div>
  )
}

const BloqueDato = ({ title, dato }) => {
  return (
    <div className='bloque-dato'>
      <span className='bloque-dato-tit'>{title}</span>
      <span className='bloque-dato-dato'>{dato}</span>
    </div>
  )
}