import React, { useContext } from 'react'
import {
  Switch,
  Route,
} from "react-router-dom";
import Propietarios from './Propietarios';
import Propietario from './Propietarios/Propietario';
import Productor from './Productores/Productor';
import Productores from './Productores';
import Categorias from './Categorias';
import Categoria from './Categorias/Categoria';
import Ciudades from './Ciudades';
import Ciudad from './Ciudades/Ciudad';
import FormasPago from './FormasPago';
import FormaPago from './FormasPago/FormaPago';
import FormaEnvio from './FormasEnvio/FormaEnvio';
import FormasEnvio from './FormasEnvio';
import Bancos from './Bancos';
import Banco from './Bancos/Banco';
import Empresa from './Empresas/Empresa';
import Empresas from './Empresas';
import Usuarios from './Usuarios';
import Usuario from './Usuarios/Usuario';
import Evento from './Eventos/Evento';
import Eventos from './Eventos';
import Recintos from './Recintos';
import Recinto from './Recintos/Recinto';
import Productos from './Productos';
import Producto from './Productos/Producto';
import Permitido from 'Procesos';
import { AppContext } from 'App';
import Boletosh from './Boletosh';
import Boletoh from './Boletosh/Boletoh';
import Promocion from './Promociones/Promocion';
import Promociones from './Promociones';

// const Rutas = ({ table, form, tableComp, formComp }) => {
//   return (
//     <React.Fragment>
//       <Route exact={true} path={`/Catalogo/${table}`} component={tableComp} />
//       <Route exact={true} path={`/Catalogo/${form}/:id?`} component={formComp} />
//     </React.Fragment>
//   )
// }

const Ruta = (path, comp, type, model = path) => (
  { path, comp, type, model }
)

const Rutas = [
  Ruta('Propietarios', Propietarios, 0, 'Propietario'),
  Ruta('Propietario', Propietario, 1),
  Ruta('Productores', Productores, 0, 'Productor'),
  Ruta('Productor', Productor, 1),
  Ruta('Boletos', Boletosh, 0, 'Boletoh'),
  Ruta('Boletoh', Boletoh, 1, 'Boletoh'),
  Ruta('Categorias', Categorias, 0, 'Categoria'),
  Ruta('Categoria', Categoria, 1),
  Ruta('Bancos', Bancos, 0, 'Banco'),
  Ruta('Banco', Banco, 1),
  Ruta('Empresas', Empresas, 0, 'Empresa'),
  Ruta('Empresa', Empresa, 1),
  Ruta('Usuarios', Usuarios, 0, 'Usuario'),
  Ruta('Usuario', Usuario, 1),
  Ruta('Eventos', Eventos, 0, 'Evento'),
  Ruta('Evento', Evento, 1),
  Ruta('Ciudades', Ciudades, 0, 'Ciudad'),
  Ruta('Ciudad', Ciudad, 1),
  Ruta('Recintos', Recintos, 0, 'Recinto'),
  Ruta('Recinto', Recinto, 1),
  Ruta('Productos', Productos, 0, 'Producto'),
  Ruta('Producto', Producto, 1),
  Ruta('Formas-Pago', FormasPago, 0, 'Forma-Pago'),
  Ruta('Forma-Pago', FormaPago, 1),
  Ruta('Formas-Envio', FormasEnvio, 0, 'Forma-Envio'),
  Ruta('Forma-Envio', FormaEnvio, 1),
  Ruta('Promociones', Promociones, 0, 'Promocion'),
  Ruta('Promocion', Promocion, 1),
]

export default function Catalogo() {
  // console.log(LasRutas)

  const AppValues = useContext(AppContext)
  return (
    <div>
      <div>
        <Switch>
          {
            Rutas.map((ruta, ri) => {
              // console.log(`Catalogo/${ruta.path}${ruta.type === 1 ? '/:id?' : ''}`)
              // console.log(AppValues, ruta.model)
              if(!Permitido.Ver(AppValues, ruta.model)) return null;
              return (
                <Route
                  key={`${ruta.path}-${ri}`}
                  exact={true}
                  path={`/Catalogo/${ruta.path}${ruta.type === 1 ? '/:id?' : ''}`}
                  component={ruta.comp}
                />
              )
            })
          }

          {/* <Route exact={true} path="/Catalogo/Propietarios" component={Propietarios} />
          <Route exact={true} path="/Catalogo/Propietario/:id?" component={Propietario} />

          <Route exact={true} path="/Catalogo/Productores" component={Productores} />
          <Route exact={true} path="/Catalogo/Productor/:id?" component={Productor} />

          <Route exact={true} path="/Catalogo/Categorias" component={Categorias} />
          <Route exact={true} path="/Catalogo/Categoria/:id?" component={Categoria} />

          <Route exact={true} path="/Catalogo/Ciudades" component={Ciudades} />
          <Route exact={true} path="/Catalogo/Ciudad/:id?" component={Ciudad} />

          <Route exact={true} path='/Catalogo/Formas-Pago' component={FormasPago} />
          <Route path='/Catalogo/Forma-Pago/:id?' component={FormaPago} />

          <Route exact={true} path='/Catalogo/Formas-Envio' component={FormasEnvio} />
          <Route path='/Catalogo/Forma-Envio/:id?' component={FormaEnvio} />

          <Route exact={true} path='/Catalogo/Bancos' component={Bancos} />
          <Route exact={true} path='/Catalogo/Banco/:id?' component={Banco} />

          <Route exact={true} path='/Catalogo/Empresas' component={Empresas} />
          <Route exact={true} path='/Catalogo/Empresa/:id?' component={Empresa} />

          <Route exact={true} path='/Catalogo/Usuarios' component={Usuarios} />
          <Route exact={true} path='/Catalogo/Usuario/:id?' component={Usuario} />

          <Route exact={true} path='/Catalogo/Eventos' component={Eventos} />
          <Route exact={true} path='/Catalogo/Evento/:id?' component={Evento} />

          <Route exact={true} path='/Catalogo/Recintos' component={Recintos} />
          <Route exact={true} path='/Catalogo/Recinto/:id?' component={Recinto} />

          <Route exact={true} path='/Catalogo/Productos' component={Productos} />
          <Route exact={true} path='/Catalogo/Producto/:id?' component={Producto} /> */}

          {/* <React.Fragment>
            <Route exact={true} path='/Catalogo/Productos' component={Productos} />
            <Route exact={true} path='/Catalogo/Producto/:id?' component={Producto} />
          </React.Fragment> */}
        </Switch>
      </div>
    </div>
  )
}
