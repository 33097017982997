export const capitalize = (word) => {
  return word.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
};

export const AppTitle = () => 'T2 Arema';

export const AppTitleF = (word) => {
  if(!word) return 'Sistema'
  let Word = capitalize(word)
  Word = Word.replace('-', ' de ')
  return Word;
}
export const SetAppTitle = (word) => {
  let title = AppTitleF(word);
  document.title = `${AppTitle()} — ${title}`;
}