const Theme = {
  primary: '#E37B00',
  secondary: '#000',
  primaryLight: '#f69a38'
}

// const Theme = {
//   primary: '#BCD431',
//   secondary: '#000',
//   primaryLight: '#f69a38'
// }

export default Theme;