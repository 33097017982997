import React from 'react'
import './Butacas.css'

export function BaseButacas() {

  return (
    <Butacas />
  )
}

export default function Butacas({ filas = 10, columnas = 10, children, width = '100%', height = '100vh', onClick = () => {} }) {
  // console.log(children)

  const onMouseMove = (event) => {
    // console.log('MOUSE MOVE SVG')
  }

  const onMouseDown = () => {
    // console.log('CLICK SVG')
    document.butacasMouseDown = true;
    // document.mousedown
  }
  const onMouseUp = () => {
    // console.log('UNCLICK SVG')
    document.butacasMouseDown = false;
    // document.mousedown
  }
  const onMouseLeave = () => {
    // console.log('LEAVE SVG')
    document.butacasMouseDown = false;
    // document.mousedown
  }
  const onSVGClick = () => {
    onClick()
  }


  return (
    <div className='butacas'>
      <svg
        width={width}
        height={height}
        onMouseMove={onMouseMove}
        onClick={onSVGClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </svg>
    </div>
  )
}

export const Butaca = ({ selected = false, size = 30, br = 10, fila = 0, columna = 0, onClick, onMouseEnter, onMouseDown, butaca = null, muerta = false, seccion = null }) => {
  let X = (size * columna) + (2 * columna);
  let Y = (size * fila) + (2 * fila);
  // const onMouseEnter = (event) => {
  //   console.log(event)
  // }

  const onClickButaca = (event) => {
    onClick(event)
  }
  const onMouseEnterButaca = (event) => {
    // console.log('MOUSE ENTER BUTACA', document.mouseDown)
    // if (document.mouseDown)
    //   onClick(event, true)
    onMouseEnter(event)
  }
  const onMouseDownButaca = (event) => {
    onMouseDown(event)
  }

  // console.log(`Butaca en ${fila}, ${columna}`)

  return (
    // <svg height={`${size}`} width={`${size}`} className='butaca'>
    <React.Fragment>
      <g id="AremaButaca">
        <path
          className={`butaca 
        ${selected ? 'selected' : ''}
        ${butaca ? 'esButaca' : ''}
        ${muerta ? 'muerta' : ''}
        `
          }
          onClick={onClickButaca}
          onMouseEnter={onMouseEnterButaca}
          onMouseDown={onMouseDownButaca}
          title='asd'
          d={`
          M ${X},${Y + br}
          q 0,-${br} ${br},-${br}
          l ${size - (br * 2)},0
          q ${br},0 ${br},${br}
          l 0,${size - (br * 2)}
          q 0,${br} -${br},${br}
          l -${size - (br * 2)},0
          q -${br},0 -${br},-${br}
          z
          `}
        />
        <text x={`${X + (br / 2)}`} y={`${Y + (size - (br / 2))}`} fontSize="12" fill="black" stroke="black" strokeWidth=".06">
          {butaca || ''}
        </text>
        <text x={`${X + 5}`} y={`${Y + 10}`} fontSize="10" fill="black" stroke="black" strokeWidth=".06">
          {seccion || ''}
        </text>
      </g>
    </React.Fragment>
    // </svg>
  )
}

// const Butaca = ({ selected = true }) => {

//   return (
//     <div className={`butaca ${selected ? 'selected' : ''}`}>

//     </div>
//   )
// }
