import { Button } from 'antd';
import React, { useRef, useState } from 'react'

export default function InputFile({ onChange = () => { } }) {
  let inputRef = useRef(null);
  let [file, setFile] = useState({});

  const onButtonClick = () => {
    // console.log(inputRef.current.click)
    inputRef.current.click();
  }

  const onInputChange = (e) => {
    const [file] = e.target.files;
    console.log(file);
    setFile(file);
    onChange(file);
    inputRef.current.value = null;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button style={{ width: '100%' }} onClick={onButtonClick}>Subir archivo</Button>
      <span style={{ marginLeft: 8, color: 'grey' }}>{file.name}</span>
      <input type='file' hidden ref={inputRef} onChange={onInputChange} />
    </div>
  )
}
