import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import { Input, Button, message } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import BackButton from 'components/BackButton/BackButton';

export default function Boletoh() {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('id'),
      Form.Field('text', 'Nombre', 'Nombre', 6, { required: true }),
      Form.Field('text', 'Descripcion', 'Descripción', 6, { required: true }),
      Form.Field('radio', 'Tipo', 'Tipo', 12, { required: true }, [
        { Value: '1', Label: 'Para todos los propietarios' },
        { Value: '2', Label: 'Exclusivo de un propietario' }
      ], undefined, (val, fields) => TipoChange(val, fields)),
      Form.Field('selectsearch', 'PropietarioExcl', 'Propietario exclusivo', 12, { required: false }, [], 'TODOS', null, false),
    ], ['Guardar'], 'boletoh', false)
  );
  const [Filas, setFilas] = useState([]);
  const Params = useParams();
  const History = useHistory();

  useEffect(() => {
    fillForm();
    cargarFilas();
    // eslint-disable-next-line
  }, []);

  const TipoChange = (val, fields) => {
    console.log(val)
    let newForm = { ...FormData, Fields: fields };
    if (val === '1') {
      newForm = Form.FieldMod(newForm, 'PropietarioExcl', 'Value', 'TODOS');
      newForm = Form.FieldMod(newForm, 'PropietarioExcl', 'Disabled', true);
    }
    else if (val === '2') {
      newForm = Form.FieldMod(newForm, 'PropietarioExcl', 'Value', undefined);
      newForm = Form.FieldMod(newForm, 'PropietarioExcl', 'Disabled', false);
    }
    setFormData({ ...newForm })
  }

  const fillForm = async () => {
    try {
      let Res = await Http.get('boletoh/form');
      // console.log(Res)
      if (!Res.error) {
        setFormData(Form.FieldOptions({ ...FormData }, 'PropietarioExcl', Res.data.propietarios, 'idpropietario', 'descripcion'))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const cargarFilas = async () => {
    if (!Params.id) return;
    try {
      let Res = await Http.get(`boletoh/${Params.id}/filas`);
      console.log(Res)
      if (!Res.error) {
        setFilas(Res.data);
      }
    } catch (error) {

    }
  }

  const onSubmit = async (Values, Button) => {
    // console.log(`[Submit] Botón ${Button}, valores: `, Values)
    let Req = {
      ...Values,
      id: Params.id,
      Filas,
    }
    // console.log(Req)
    // return
    try {
      let Res = await Http.post(`boletoh/save/filas`, Req);
      console.log(Res)
      if (!Res.error) {
        message.info(`Guardado`);
        if (!Params.id) {
          History.goBack()
        }
      }
      else {
        message.warn(`Error: ${Res.message}`);
      }
    } catch (error) {
      message.warn(`Error al guardar`);
      console.log(`Error al guardar (doSubmit) para Boletoh: `, error)
    }
    return;
  }

  const addFila = () => {
    setFilas(prevFilas => {
      prevFilas.push({
        Contenido: undefined,
        Descripcion: undefined,
      })
      return [...prevFilas]
    })
  }
  const onFilaChange = (Fi, Vals) => {
    setFilas(prevFilas => {
      prevFilas[Fi] = { ...prevFilas[Fi], ...Vals };
      return [...prevFilas];
    })
  }
  const onFialDelete = (Fi) => {
    setFilas(prevFilas => {
      prevFilas = prevFilas.map((fila, fi) => {
        if (fi !== Fi) return fila;
        fila.deleted = true;
        return fila;
      })
      return [...prevFilas];
    })
  }

  return (
    <div>
      <BackButton />
      <h2>Boleto</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      >
        <div style={{ textAlign: 'right' }}>
          <Button icon='plus' onClick={addFila}>Fila</Button>
        </div>
        <div style={{paddingTop: 10}}>
          {
            Filas.map((Fila, Fi) => (!Fila.deleted &&
              <BoletoFila
                key={`${Fi}.`}
                Contenido={Fila.Contenido}
                Descripcion={Fila.Descripcion}
                onChange={(Vals) => onFilaChange(Fi, Vals)}
                onDelete={() => onFialDelete(Fi)}
              />
            ))
          }
        </div>

      </FormComponent>
    </div>
  )
}

const BoletoFila = ({ Contenido, Descripcion, onChange, onDelete }) => {
  const onDescChange = (event) => {
    Descripcion = event.target.value;
    onChange({ Contenido, Descripcion });
  }
  const onContChange = (event) => {
    Contenido = event.target.value;
    onChange({ Contenido, Descripcion });
  }

  return (
    <div className='row'>
      <div className='col-md-4'>
        <Input
          placeholder='Descripción'
          value={Descripcion}
          onChange={onDescChange}
        />
      </div>
      <div className='col-md-7'>
        <Input
          placeholder='Contenido'
          value={Contenido}
          onChange={onContChange}
        />
      </div>
      <div className='col-md-1'>
        <Button
          icon='delete'
          onClick={() => onDelete()}
          shape='circle'
        />
      </div>
    </div>
  )
}