import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Promociones() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('idpropietario', 'Propietario'),
      Table.Field('promocion', 'Promoción'),
      Table.Field('descrip', 'Descripción'),
      Table.Field('fec_ini', 'Fecha de inicio'),
      Table.Field('fec_fin', 'Fecha de fin'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('promocion', 'promocion', 'idpromo')
    )
  )
  
  return (
    <div>
      <h2>Promociones</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
