import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import BackButton from 'components/BackButton/BackButton';

export default function FormaPago() {
  
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('number', 'FORMAPAGO', 'Forma de pago', 4, { required: true }),
      Form.Field('text', 'DESCRIPCION', 'Descripción', 8, { required: true }),
      Form.Field('number', 'COSTO', 'Costo', 6, { required: true }),
      Form.Field('number', 'PORCIENTO', 'Porcentaje', 6, { required: true }),
      Form.Hidden('TIPO', 0),
    ], ['Guardar'], 'forma-pago')
  );
  
  useEffect(() => {
    
  }, []);
  
  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
  }
  
  return (
    <div>
      <BackButton />
      <h2>Forma de Pago</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}