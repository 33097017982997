import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import { Button, Select, Modal, message } from 'antd';
import './Proceso.css'
import Theme from 'Theme';
import Http from 'Http';
// import ProductoForm from 'components/Catalogos/Productos/Producto'
import AreaForm from 'components/Catalogos/Areas/Area'
import Global from 'Global';

export default function Pagos({ onNext, data = {}, fill = {} }) {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('textarea', 'condiciones', 'Condiciones', 12, { required: false }),
      Form.Field('selectsearchm', 'FormasEnvio', 'Formas de entrega', 6, { required: false }),
      Form.Field('selectsearchm', 'FormasPago', 'Formas de pago', 6, { required: true }),
      Form.Field('checkbox', 'cambiolocalidad', 'Permitir cambios de localidad', 6, { required: false }),
      Form.Field('number', 'comision', 'Comisión', 6, { required: false }),
    ], ['Siguiente'], null, false)
  );

  const [Areas, setAreas] = useState([]);
  const [Area, setArea] = useState(undefined);
  const [ModalArea, setModalArea] = useState(false)
  const [PreciosAreas, setPreciosAreas] = useState([])
  const [ModalProducto, setModalProducto] = useState({ visible: false, modo: 'edicion', pai: -1, producto: {} })

  useEffect(() => {
    if (data) {
      setFormData(Form.Patch({ ...FormData }, { ...data }))
      if (data.precios) {
        console.log(data.precios)
        setPreciosAreas(data.precios);
      }
    }
    if (fill) {
      setFormData(
        Form.FieldOptionsGroup({ ...FormData }, [
          Form.FOG_Field('FormasPago', fill.formas_pago, 'FORMAPAGO', 'DESCRIPCION'),
          Form.FOG_Field('FormasEnvio', fill.formas_envio, 'IDENVIO', 'DESCRIPCION'),
        ])
      )
    }
    formPreciosFill();
    propSettings();
    // eslint-disable-next-line
  }, [fill]);

  const formPreciosFill = async (pred = undefined) => {
    try {
      let Res = await Http.get(`recinto/${data.idrecinto || '15'}/areas`);
      // console.log(Res)
      if (!Res.error) {
        setAreas(Res.data)
        if (pred)
          setArea(pred)
      }
    } catch (error) {

    }
  }

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({
        ...Form.FieldModGroup(FormData, [
          Form.FMG_Field("condiciones", "Disabled", true),
          Form.FMG_Field("FormasEnvio", "Disabled", true),
          Form.FMG_Field("FormasPago", "Disabled", true),
          Form.FMG_Field("cambiolocalidad", "Disabled", true),
          Form.FMG_Field("comision", "Disabled", true),
        ])
      });
    }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    onNext({ ...Values, precios: PreciosAreas });
  }

  const addArea = () => {
    setModalArea(true);
  }
  const onAddArea = (saved, data) => {
    setModalArea(false);
    if (saved) {
      formPreciosFill(data.idarea)
      message.info('El área fue añadida a la lista')
    }
  }

  const addPrecioArea = async () => {
    if (!Area) return;
    if (PreciosAreas.some((precioArea) => !precioArea.deleted && precioArea.idarea === Area))
      return message.warn('El área ya está en los precios')
    try {
      let Res = await Http.get(`area/${Area}`);
      // console.log(Res);
      PreciosAreas.push({
        ...Res.data,
        productos: [],
        deleted: false,
      })
      setPreciosAreas([...PreciosAreas]);
    } catch (error) {

    }
    // PreciosAreas.push({
    //   idarea: Area,
    // })
  }
  const onDeletePrecioArea = (precioArea, pai) => {
    PreciosAreas[pai].deleted = true;
    setPreciosAreas([...PreciosAreas]);
  }
  const addProductoToPrecioArea = (precioArea, pai) => {
    setModalProducto({
      visible: true, modo: 'creacion', pai, producto: {
        idarea: precioArea.idarea,
        idproducto: undefined,
        precio: undefined,
        folio_precio: 0,
        costoboleto: 0,
        costoservicio: undefined,
        costotc: undefined,
        alimentos: 0,
        hospedaje: 0,
      }
    })
    // setPreciosAreas([...PreciosAreas]);
  }
  const onProductoPrecioAreaSave = (saved, producto) => {
    // console.log(producto)
    // const { modo, pai }
    setModalProducto({ ...ModalProducto, visible: false, modo: '' })
    if (ModalProducto.modo === 'creacion') {
      if (PreciosAreas[ModalProducto.pai].productos.some((prod) => !prod.deleted && prod.idproducto.toString() === producto.idproducto.toString()))
        return message.warn('El producto ya está en el área')
      PreciosAreas[ModalProducto.pai].productos.push(producto);
      setPreciosAreas([...PreciosAreas]);
    }
    else if (ModalProducto.modo === 'edicion') {
      // let Idx = PreciosAreas
      if (PreciosAreas[ModalProducto.pai].productos.some((prod, spi) => spi !== ModalProducto.pi && !prod.deleted && prod.idproducto.toString() === producto.idproducto.toString()))
        return message.warn('El producto cambió a uno que ya está en el área')
      PreciosAreas[ModalProducto.pai].productos[ModalProducto.pi] = producto;
      setPreciosAreas([...PreciosAreas]);
    }
  }
  const onPrecioDelete = (pai, pi) => {
    // console.log('Delete', pai, pi)
    PreciosAreas[pai].productos[pi].deleted = true;
    setPreciosAreas([...PreciosAreas]);
    // setPreciosAreas(PreciosAreas);
  }
  const onPrecioEdit = (pai, pi) => {
    // console.log('Edit', pai, pi)
    setModalProducto({
      visible: true, modo: 'edicion', pai, pi, producto: {
        ...PreciosAreas[pai].productos[pi]
      }
    })
  }

  return (
    <div className='pagos'>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      >
        <div className='precios' style={{ borderColor: Theme.primary }}>
          <h4 style={{ color: Theme.primary }}>Precios de áreas</h4>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <div>
              <Button icon='plus-circle' onClick={addArea}>Área</Button>
            </div>
            <div>
              <Select value={Area} onChange={(val) => setArea(val)} style={{ width: 150 }} placeholder='Área'>
                {
                  Areas.map((Area, Pi) => (
                    <Select.Option key={Area.idarea} value={Area.idarea}>{Area.area}</Select.Option>
                  ))
                }
              </Select>
              <Button icon='plus' shape='circle' onClick={addPrecioArea}></Button>
            </div>
          </div>

          <div>
            {
              PreciosAreas.length > 0 && (
                <React.Fragment>
                  <div className='row' style={{ textAlign: 'center', fontWeight: 'bolder' }}>
                    <div className='col-sm-2'>
                      Área
                  </div>
                    <div className='col-sm-8'>
                      Productos
                  </div>
                    <div className='col-sm-2'>
                      Opciones
                  </div>
                  </div>
                  {
                    PreciosAreas.map((precioArea, pai) => (!precioArea.deleted &&
                      <PrecioArea
                        key={precioArea.idarea}
                        label={precioArea.area}
                        productos={precioArea.productos}
                        onDelete={() => onDeletePrecioArea(precioArea, pai)}
                        onAdd={() => addProductoToPrecioArea(precioArea, pai)}
                        onDeletePrecio={(producto, pi) => onPrecioDelete(pai, pi)}
                        onEditPrecio={(producto, pi) => onPrecioEdit(pai, pi)}
                      />
                    ))
                  }
                </React.Fragment>
              )
            }
            {
              PreciosAreas.length === 0 && (
                <div style={{ width: '100%', textAlign: 'center', border: '1px solid', borderRadius: 4, marginTop: 10, borderColor: Theme.primary, color: Theme.primary }}>
                  Sin áreas
                </div>
              )
            }
          </div>
        </div>
      </FormComponent>

      <Modal
        title="Agregar área"
        visible={ModalArea}
        onOk={() => setModalArea(false)}
        onCancel={() => setModalArea(false)}
        footer={<div />}
        width='90%'
        destroyOnClose={true}
      >
        <AreaForm
          onSave={onAddArea}
          idrecinto={data.idrecinto || '16'}
        />
      </Modal>

      <Modal
        title="Producto"
        visible={ModalProducto.visible}
        onOk={() => setModalProducto({ ...ModalProducto, visible: false })}
        onCancel={() => setModalProducto({ ...ModalProducto, visible: false })}
        footer={<div />}
        width='50%'
        destroyOnClose={true}
      >
        <Producto
          // onChange={onProductoPrecioAreaChange}
          producto={ModalProducto.producto}
          idpropietario={data.idpropietario || 'AREMA'}
          onSave={onProductoPrecioAreaSave}
        />
      </Modal>

    </div>
  )
}

const PrecioArea = ({ label = 'Prueba', productos = [{ producto: 'Prueba', precio: 10.0 }, { producto: 'Prueba 2', precio: 110.0 }], onDelete, onAdd, onEditPrecio, onDeletePrecio }) => {
  // console.log(productos.length)
  return (
    <div className='precio-area'>
      <div className='row contenedor'>
        <div className='col-sm-2 centrado'>
          {label}
        </div>
        <div className='col-sm-8' style={{ overflow: 'auto' }}>
          {
            productos.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <td>Producto</td>
                    <td>Precio</td>
                    <td>C. por boleto</td>
                    <td>C. por servicio</td>
                    <td>C. por tc</td>
                    <td>Alimentos</td>
                    <td>Hospedaje</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {
                    productos.map((producto, pi) => (!producto.deleted &&
                      <PrecioAreaProducto
                        key={`${producto.producto}-${pi}`}
                        label={producto.producto}
                        precio={producto.precio}
                        otrosPrecios={producto}
                        onEdit={() => onEditPrecio(producto, pi)}
                        onDelete={() => onDeletePrecio(producto, pi)}
                      />
                    ))
                  }
                </tbody>
              </table>
            )
          }
          {
            productos.length === 0 && (
              <div style={{ textAlign: 'center' }}>Sin productos</div>
            )
          }
        </div>
        <div className='col-sm-2 centrado'>
          <Button htmlType='button' shape='circle' icon='plus' onClick={onAdd}></Button>
          <Button htmlType='button' shape='circle' icon='delete' onClick={onDelete}></Button>
        </div>
      </div>
    </div>
  )
}

const PrecioAreaProducto = ({ label, precio, onDelete, onEdit, otrosPrecios }) => {
  return (
    <React.Fragment>
      <tr>
        <td>{label}</td>
        <td>${precio}</td>
        <td>${otrosPrecios.costoboleto}</td>
        <td>${otrosPrecios.costoservicio}</td>
        <td>${otrosPrecios.costotc}</td>
        <td>${otrosPrecios.alimentos}</td>
        <td>${otrosPrecios.hospedaje}</td>
        <td>
          <Button icon='edit' shape='circle' onClick={onEdit}></Button>
          <Button icon='delete' shape='circle' onClick={onDelete}></Button>
        </td>
      </tr>
    </React.Fragment>
    // <div className='precio-area-prodcuto' style={{ borderBottomColor: Theme.primary }}>
    //   <div>
    //     <span>{label}</span>
    //   </div>
    //   <div>
    //     <span style={{ fontSize: 13 }}>$</span><span>{precio}</span>
    //   </div>
    //   <div>
    //     <Button icon='edit' shape='circle' onClick={onEdit}></Button>
    //     <Button icon='delete' shape='circle' onClick={onDelete}></Button>
    //   </div>
    // </div>
  )
}



export function Producto({ idpropietario = 'AREMA', producto = {}, onSave }) {
  const [Productos, setProductos] = useState([])
  // let ProductosInfo = [];
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Hidden('idprecio', null),
      Form.Field('select', 'idproducto', 'Producto', 12, { required: true }),
      Form.Field('number', 'precio', 'Precio', 12, { required: true }),
      Form.Field('number', 'costoboleto', 'Cargo por boleto', 12, { required: true }),
      Form.Field('number', 'costoservicio', 'Cargo por servicio', 12, { required: true }),
      Form.Field('number', 'costotc', 'Cargo x tc', 12, { required: true }),
      Form.Field('number', 'alimentos', 'Alimentos', 12, { required: true }),
      Form.Field('number', 'hospedaje', 'Hospedaje', 12, { required: true }),
    ], ['Guardar'], null, false)
  );
  // console.log(producto)

  useEffect(() => {
    getProductos();
    propSettings();
    // eslint-disable-next-line
  }, [])

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({
        ...Form.FieldModGroup(FormData, [
          Form.FMG_Field("costoboleto", "Disabled", true),
          Form.FMG_Field("costoservicio", "Validators", { required: false }),
          Form.FMG_Field("costoservicio", "Disabled", true),
          Form.FMG_Field("costotc", "Validators", { required: false }),
          Form.FMG_Field("costotc", "Disabled", true),
          Form.FMG_Field("alimentos", "Disabled", true),
          Form.FMG_Field("hospedaje", "Disabled", true),
        ])
      });
    }
  }

  useEffect(() => {
    console.log(producto)
    setFormData(Form.Patch({ ...FormData }, { ...producto, idproducto: producto.idproducto ? producto.idproducto.toString() : undefined }))
    // eslint-disable-next-line
  }, [producto])

  const onSubmit = (Values, Button) => {
    // console.log(Productos, Values)
    Values.producto = Productos.find((prod) => prod.idproducto.toString() === Values.idproducto.toString()).producto;
    onSave(true, Values)
  }

  const getProductos = async () => {
    try {
      let Res = await Http.get(`propietario/${idpropietario}/productos`);
      if (!Res.error) {
        setProductos(Res.data);
        setFormData(
          Form.FieldOptionsGroup({ ...FormData }, [
            Form.FOG_Field('idproducto', Res.data, 'idproducto', 'producto'),
          ])
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
        label={true}
      />
    </div >
  )
}
