import { Button } from 'antd';
import React from 'react'
import { useHistory } from "react-router-dom";

export default function BackButton() {
  const History = useHistory();
  return (
    <div style={{ marginBottom: 10 }}>
      <Button onClick={() => History.goBack()} icon='arrow-left' type='ghost' size='small'>Regresar</Button>
    </div>
  )
}
