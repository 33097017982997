import React from 'react'
// import { AppContext } from 'App';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

class Permitido {
  static V(Context, Que, Cual = 0) {
    // console.warn(Context)
    if(!Context.procesos) return true;
    const AppValues = Context;
    const AppProcesos = AppValues.procesos.listado;
    const UserPermisos = AppValues.procesos.permisos;
    // const a = AppValues.procesos;
    // console.log(AppProcesos, UserPermisos)
    Que = capitalize(Que);
    Que = Que.replace('-', '');
    Que = Que.toUpperCase();
    // console.log(`Permitido el proceso ${Cual} en ${Que}?`)
    if (!AppProcesos[Que] || !AppProcesos[Que][Cual]) return false;
    let Permitido = UserPermisos.includes(AppProcesos[Que][Cual])
    // console.log(Permitido)
    return Permitido;
  }

  static Ver(Context, Que) {
    return this.V(Context, Que, 0);
  }

  static Agregar(Context, Que) {
    return this.V(Context, Que, 1);
  }

  static Modificar(Context, Que) {
    return this.V(Context, Que, 2);
  }

  static Eliminar(Context, Que) {
    return this.V(Context, Que, 3);
  }

  static Imprimir(Context, Que) {
    return this.V(Context, Que, 4);
  }

  static No() {
    return <div></div>
  }
}

/**
 * 
 * @param {*} Que Descripción del permiso general
 * @param {*} Cual 0 (PREDEM) VER, 1 AGREGAR, 2 MODIFICAR, 3 ELIMINAR, 4 IMPRIMIR
 */
// function Permitido(Que, Cual = 0) {
//   // console.log('hola')
//   const AppValues = useContext(AppContext);
//   const AppProcesos = AppValues.procesos.listado;
//   const UserPermisos = AppValues.procesos.permisos;
//   // const a = AppValues.procesos;
//   console.log(AppProcesos, UserPermisos)
//   if (!AppProcesos[Que] || !AppProcesos[Que][Cual]) return false;
//   let Permitido = UserPermisos.includes(AppProcesos[Que][Cual])
//   console.log(Permitido)
//   return Permitido;
// }

export default Permitido;