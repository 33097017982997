import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function FormasPago() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('FORMAPAGO', 'Forma de pago'),
      Table.Field('DESCRIPCION', 'Descripción'),
      Table.Field('COSTO', 'Costo'),
      Table.Field('PORCIENTO', 'Porcentaje'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('forma-pago', 'DESCRIPCION', 'FORMAPAGO')
    )
  )
  
  return (
    <div>
      <h2>Formas de Pago</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
