import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Recursos() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('idpropietario', 'Propietario'),
      Table.Field('descripcion', 'Descripción'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('recurso', 'descripcion', 'idrecurso')
    )
  )
  
  return (
    <div>
      <h2>Recursos</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
