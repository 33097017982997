import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Categorias() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('categoria', 'Categoría'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('categoria', 'categoria', 'idcategoria')
    )
  )
  
  return (
    <div>
      <h2>Categorias</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
