import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import BackButton from 'components/BackButton/BackButton';

export default function Banco() {
  
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('idbanco'),
      Form.Field('select', 'idpropietario', 'Propietario', 6, { required: true }),
      Form.Field('text', 'banco', 'Banco', 6, { required: true }),
    ], ['Guardar'], 'banco')
  );
  
  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, []);

  const fillForm = async () => {
    try {
      let Res = await Http.get('productor/form');
      if (!Res.error) {
        setFormData(Form.FieldOptions({ ...FormData }, 'idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'))
      }
    } catch (error) {

    }
  }
  
  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
  }
  
  return (
    <div>
      <BackButton />
      <h2>Banco</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
