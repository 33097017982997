import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Fechas() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('idpropietario', 'Propietario'),
      Table.Field('ciudad', 'Ciudad'),
      Table.Field('recinto', 'Recinto'),
      Table.Field('evento', 'Evento'),
      Table.Field('fecha', 'Fecha'),
      Table.Field('hora', 'Hora'),
      Table.Field('categoria', 'Categoría'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('calendario', 'evento', 'idcalendario')
    )
  )
  
  return (
    <div>
      <h2>Programación de eventos</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
