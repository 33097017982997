import axios from 'axios';
// import React from 'react'
import { message } from 'antd';
import Global from 'Global';
// import { useContext } from 'react';
// import { AppContext } from 'App';
// import a from 'Global'

export interface HttpRes {
	data: any;
	error: boolean;
	errorCode?: number;
	errorMsg?: string;
	status: number;
	message?: string;
}

// const HttpContext = () => {
// 	let AppValues = useContext(AppContext);
// 	console.log(AppValues)
// 	return;
// }

export default class Http {

	// // private url = 'http://127.0.0.1:3333'
	// private url = 'https://boletera.com.mx/action'
	private url = Global.API_URL;

	static async post(ep: string, data: any, auth = true): Promise<HttpRes> {
		// console.log('nuevo post')
		console.log(`[POST] ${Http.URL(auth, ep)}`)
		try {
			let AxiosRequest = await axios.post(Http.URL(auth, ep), data, Http.Headers());
			return AxiosRequest.data;
		} catch (error) {
			console.error(`[Http] Request error (post ${Http.URL(auth, ep)}): `, error.response);
			this.ProcesarError(error.response)
			return { data: null, error: true, errorCode: 0, status: 400, errorMsg: 'Http Error' };
		}
	}

	static async get(ep: string, params: any = [], auth = true): Promise<HttpRes> {
		// console.log('nuevo get')
		// params.push(['rpr', 'ads'])
		let UrlParams = `${Http.URL(auth, ep)}${this.ArrayToUrlParams(params)}`;
		// console.log(UrlParams)
		console.log(`[GET] ${UrlParams}`)
		// HttpContext()
		// let AppValues = useContext(AppContext);
		// console.log(AppValues)
		try {
			let AxiosRequest = await axios.get(UrlParams, { ...Http.Headers() });
			return AxiosRequest.data;
		} catch (error) {
			console.error(`[Http] Request error (get ${Http.URL(auth, ep)}): `, error.response);
			this.ProcesarError(error.response)
			return { data: null, error: true, errorCode: 0, status: 400, errorMsg: 'Http Error' };
		}
	}

	static async delete(ep: string, auth = true): Promise<HttpRes> {
		// console.log('nuevo delete')
		console.log(`[DELETE] ${Http.URL(auth, ep)}`)
		try {
			let AxiosRequest = await axios.delete(Http.URL(auth, ep), Http.Headers());
			return AxiosRequest.data;
		} catch (error) {
			console.error(`[Http] Request error (delete ${Http.URL(auth, ep)}): `, error.response);
			return { data: null, error: true, errorCode: 0, status: 400, errorMsg: 'Http Error' };
		}
	}

	static ProcesarError(error: any) {
		const { data, status } = error;
		if (!data) return;
		console.log(data);
		if (status === 403) {
			if (data.status === 4)
				message.info('El sistema está cerrado')
			if (data.status === 5)
				message.info('La sesión expiró')
		}
	}

	static ArrayToUrlParams(Params: any[]) {
		if (Params.length < 1) return '';
		let Url = '?';
		Params.forEach((param) => {
			if (param[1].length < 1) return;
			Url = `${Url}${param[0]}=${param[1]}&`;
		})
		return Url;
	}

	static URL(auth: boolean, ep: string): string {
		let HttpReq = new Http();
		return `${HttpReq.url}/${auth ? 'auth/' : ''}${ep}`;
	}
	static Headers() {
		// HttpContext()
		return {
			headers: {
				// Authorization: `Bearer ${localStorage.getItem('bt')}`
				Token: `${localStorage.getItem('bt')}`,
				// Prop: 'asd'
			}
		}
	}

}