import React, { Component } from 'react'
import { ChromePicker } from 'react-color';
// import { Input } from 'antd';
import './Form.css';

// CAMBIAR EL COLOR EN LA BOX

const rgbToCssRgb = (rgbObj) => {
  if (!rgbObj) {
    console.log('Error en el color: ', rgbObj)
    return rgbToCssRgb({a: 1, r: 0, g: 0, b: 0})
  };
  return `rgb(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${rgbObj.a})`
}

export default class InputColor extends Component {

  state = {
    colorVisible: false,
    colorSeleccion: { r: 0, g: 0, b: 0, a: 0 },
    focus: false,
  }

  colorPrevio = { r: 0, g: 0, b: 0, a: 0 }
  rgbToCssRgb = (rgbObj) => {
    if (!rgbObj) {
      console.log('Error en el color: ', rgbObj)
      // return rgbToCssRgb({a: 1, r: 0, g: 0, b: 0})
      return rgbToCssRgb(this.colorPrevio)
    };
    this.colorPrevio = rgbObj;
    return `rgb(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${rgbObj.a})`
  }

  componentDidMount() {
    // console.log(this.props)
    // this.setState({ colorSeleccion: this.props.value })
    // console.log(this.state.colorSeleccion, this.props.defaultValue)
  }

  componentDidUpdate() {
    // console.log('UPDATE', this.props)
  }

  // componentWillReceiveProps(props) {

  // }

  // static getDerivedStateFromProps(props, state) {
  //   // console.log(props, this.props)
  //   console.log(props, state)
  //   if (props.value !== state.colorSeleccion) {
  //     state.colorSeleccion = props.value;
  //     // return state;
  //     return state;
  //   }
  //   console.log(props, state)
  //   return null;
  // }

  inputFocus = () => {
    // this.setState({ focus: true, colorVisible: false })
  }

  inputBlur = () => {
    // this.setState({ focus: false })
    // console.log('blur')
  }

  colorClick = () => {
    // const { colorVisible } = this.state;
    // console.log(colorVisible)
    this.setState({ colorVisible: !this.state.colorVisible })
  }

  render() {
    let {
      // key,
      control,
      name,
      // type,
      // defaultValue,
      disabled,
      // rules,
      // placeholder,
      // className,
      autoFocus,
      // style,
      // size,
      value,
    } = this.props;
    const {
      colorVisible,
      // colorSeleccion,
      focus
    } = this.state;
    // console.log(this.props)
    if(!value) value = this.colorPrevio;
    // console.log(value)
    return (
      <div style={{ position: 'relative' }} className='input-color'>
        <div className={`row contenedor ${focus ? 'focus' : ''}`}>
          <div className='col-4'>
            <div style={{ backgroundColor: this.rgbToCssRgb(value) }} className='color' onClick={this.colorClick} />
          </div>
          <div className='col-8'>
            <input
              control={control}
              name={name}
              disabled={disabled}
              autoFocus={autoFocus}
              value={this.rgbToCssRgb(value, this.colorPrevio)}
              // onChange={(e) => { this.setState({ colorSeleccion: e.target.value }) }}
              onChange={(e) => {  }}
              onFocus={this.inputFocus}
              onBlur={() => this.inputBlur()}
            />
          </div>
        </div>

        <div hidden={!colorVisible} style={{ position: 'absolute', zIndex: 1000 }}>
          <ChromePicker
            style={{ zIndex: 1000 }}
            color={value}
            // onChangeComplete={(color) => { console.log(color); this.setState({ colorSeleccion: color.rgb }); }}
            // onChangeComplete={(color) => { console.log(color); this.props.onChange(color.rgb) }}
            onChangeComplete={(color) => { this.props.onChange(color.rgb) }}
          />
        </div>

        {/* <div>
        </div> */}
      </div>
    )
  }
}
