import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Boletosh() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('Nombre', 'Nombre'),
      Table.Field('Descripcion', 'Descripción'),
      Table.Field('PropietarioExcl', 'Propietario'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('boletoh', 'Nombre', 'id')
    )
  )
  
  return (
    <div>
      <h2>Boletos</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
