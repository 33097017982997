import React from 'react'
import {
  Switch,
  Route,
} from "react-router-dom";
import Seguridad from './Seguridad';
import Proceso from './Seguridad/Proceso';
import DatosGenerales from './DatosGenerales';
import DatoGeneral from './DatosGenerales/DatoGeneral';
import AperturaT2 from './AperturaT2/AperturaT2';
import Personalizacion from './Personalizacion/Personalizacion';
// import AperturaT2 from './AperturaT2';
// import Aperturas from './AperturaT2/Aperturas';

export default function Sistema() {
  return (
    <div>
      <Switch>
        <Route path="/Sistema/Procesos">
          <Seguridad />
        </Route>
        <Route path="/Sistema/Proceso/:id?">
          <Proceso />
        </Route>
        <Route path="/Sistema/Generales">
          <DatosGenerales />
        </Route>
        <Route path="/Sistema/Spec/:id?">
          <DatoGeneral />
        </Route>
        <Route path="/Sistema/AperturaT2">
          <AperturaT2 />
        </Route>
        <Route path="/Sistema/Personalizacion">
          <Personalizacion />
        </Route>
        {/* <Route path="/Sistema/Aperturas">
          <Aperturas />
        </Route>
        <Route path="/Sistema/Apertura/:id?">
          <AperturaT2 />
        </Route> */}
        {/* <Route path="/Evento">
        <Evento />
      </Route> */}
      </Switch>
    </div>
  )
}
