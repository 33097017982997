import React, { useEffect, useState } from 'react'
import Http from 'Http';
import './Disponibilidad.css';
import { Spin } from 'antd';

export default function Distribucion({ disponibilidad: iddisponibilidad }) {

  const [Detalles, setDetalles] = useState([]);

  useEffect(() => {
    loadDistribucion();
    // eslint-disable-next-line
  }, [])

  const loadDistribucion = async () => {
    if (!iddisponibilidad) return;
    try {
      let Res = await Http.get(`recurso-disponibilidad/${iddisponibilidad}/distribucion`);
      console.log(Res.data);
      if (!Res.error) {
        setDetalles(Res.data)
      }
    } catch (error) {

    }
  }

  return (
    <div className='distribucion'>
      {
        (!Detalles || Detalles.length === 0) && (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        )
      }
      {
        Detalles && Detalles.length > 0 && (
          <table>
            <thead>
              <tr>
                <td>Recurso</td>
                <td>Fecha</td>
                <td>Hora</td>
                <td>Disponible</td>
                <td>Usado</td>
                <td>Venta</td>
              </tr>
            </thead>
            <tbody>
              {
                Detalles.map((detalle, di) => (
                  <tr key={detalle.idprogramacion}>
                    <td>{detalle.descripcion}</td>
                    <td>{detalle.fecha}</td>
                    <td>{detalle.hora}</td>
                    <td>{detalle.disponible}</td>
                    <td>{detalle.usado || 0}</td>
                    <td>{detalle.venta || 0}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        )
      }

    </div>
  )
}
