import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import BackButton from 'components/BackButton/BackButton';

export default function Evento({ onSave = null, idpropietario = null, goBack = true }) {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('idevento'),
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: true }),
      Form.Field('text', 'nombre_display', 'Nombre Display', 12, { required: false }),
      Form.Field('text', 'nombre', 'Nombre', 12, { required: true }),
      Form.Field('text', 'nombre2', 'Nombre del Evento 2', 12, { required: false }),
      Form.Field('text', 'nombre3', 'Nombre del Evento 3', 12, { required: false }),
      Form.Field('text', 'comentario', 'Comentario', 8, { required: false }),
      Form.Field('checkbox', 'card_secure', 'Card secure', 4, { required: true }, [], 1, null, false),
    ], ['Guardar'], 'evento')
  );

  useEffect(() => {
    fillForm();
    if (idpropietario) {
      setFormData(Form.Patch({ ...FormData }, { idpropietario }))
    }
    // eslint-disable-next-line
  }, []);

  const fillForm = async () => {
    try {
      let Res = await Http.get('productor/form');
      if (!Res.error) {
        setFormData(Form.FieldOptions({ ...FormData }, 'idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'))
      }
    } catch (error) {

    }
  }

  const onSubmit = async (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
  }

  return (
    <div>
      {goBack && <BackButton />}
      <h2>Evento</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
        onSave={onSave}
      />
    </div>
  )
}
