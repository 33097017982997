import React, { useState } from 'react'
import './Login.css'
import Theme from '../../Theme'
import { FormComponent } from '../Form/Form';
import { Form } from '../Form/FormTypes';
import Http from '../../Http';
import { message } from 'antd';

export default function Login({ onLogin }) {
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('text', 'user', 'Usuario', 12, { required: true }, [], undefined),
      Form.Field('password', 'password', 'Contraseña', 12, { required: true }, [], undefined),
    ], ['Ingresar'], '', false)
  );
  const [Loading, setLoading] = useState(false)

  const onSubmit = async (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    setLoading(true)
    const { user, password } = Values;
    try {
      let Res = await Http.post('login', { user, password }, false);
      console.log(Res)
      if (!Res.error) {
        // localStorage.setItem('bt', Res.data.Auth.token);
        localStorage.setItem('bt', Res.data.token);
        onLogin();
      }
      else {
        message.warn(`Error: ${Res.message}`);
      }
    } catch (error) {
      message.warn('Error al iniciar sesión');
    }
    setLoading(false)
  }

  return (
    <div>
      <div className='login-bg' style={{ backgroundColor: Theme.primary }}>
        <div className='login-box'>
          <FormComponent
            data={FormData}
            onSubmit={onSubmit}
            onValueChanged={setFormData}
            loading={Loading}
          />
        </div>
      </div>
    </div>
  )
}
