import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { Steps, message, Button, Modal } from 'antd'
import { ReconciliationOutlined, ScheduleOutlined, IdcardOutlined, WalletOutlined, GlobalOutlined } from '@ant-design/icons';
import Generales from './Proceso/Generales';
import Http from 'Http';
import Evento from './Proceso/Evento';
import Serie from './Proceso/Serie';
import Pagos from './Proceso/Pagos';
import Internet from './Proceso/Internet';
import { Form } from 'components/Form';
import Multiplicar from './Multiplicar';
import Global from 'Global';
// import Theme from 'Theme';

let Params = [];
export default function EventoProceso() {
  const [Step, setStep] = useState(0)
  const [Fill, setFill] = useState({});
  const [Data, setData] = useState({});
  const [ModalMultiplicar, setModalMultiplicar] = useState(false);
  const [Loading, setLoading] = useState(false)
  const RouteParams = useParams();
  const History = useHistory();
  const stepChange = (current) => {
    if (!RouteParams.id) return;
    setStep(current)
  }

  useEffect(() => {
    if (!RouteParams.id)
      fillForm();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getCalendario();
    // eslint-disable-next-line
  }, [RouteParams])

  const getCalendario = async () => {
    const { id: idcalendario } = RouteParams;
    // console.log(idcalendario)
    if (idcalendario) {
      try {
        let Res = await Http.get(`calendario/${idcalendario}/fecha`);
        // console.log(Res);
        if (!Res.error) {
          let { data } = Res;
          data.idproductor = data.idproductor.toString();
          data.idevento = data.idevento.toString();
          data.idrecinto = data.idrecinto.toString();
          // data.puerta_ini = new Date(new Date(data.puerta_ini).getTime() + (6 * 60 * 60 * 1000))
          // data.puerta_ini = new Date(data.puerta_ini).utc Date.
          setData(data)
          // console.log(Fill, Res.data.fill)
          setFill({ ...Fill, ...Res.data.fill })
          fillForm([], Res.data.fill);
        }
      } catch (error) {
        console.log(error)
      }
    }
    else {
      //   if(!Data) return;
      //   setData({...Form.Reset(Data)})
      // console.log('debug ejje')
      setData({})
    }
  }

  const fillForm = async (fillParams = [], befill = {}) => {
    // console.log(Params, fillParams);
    // if(RouteParams)
    if(Global.PROP === "BPMEX") {
      Params = Form.MergeParams(Params, [["prop", Global.PROP]])
      // Params = Form.MergeParams(Params, [["prop", "AREMA"]])
    }

    Params = Form.MergeParams(Params, fillParams)
    try {
      let Res = await Http.get('calendario/form', Params);
      // console.log(Res.data)
      if (!Res.error) {
        // console.log(Fill, Res.data)
        setFill({ ...Res.data, ...befill })
        // setFill({ ...befill, ...Res.data })
        // setFill(Res.data)
      }
    } catch (error) {

    }
  }

  const onNext = (data, step) => {
    console.log(`[onNext] Desde step ${step} `, data)
    setData({ ...Data, ...data })
    if (step === 4) {
      console.log(data)
      submit({ ...Data, ...data });
      return;
    }
    setStep(step + 1)
  }

  const submit = async (data) => {
    console.log(data);
    // return;
    setLoading(true);
    try {
      let Res = await Http.post(`calendario/save/fecha`, data);
      console.log(Res);
      if (!Res.error) {
        History.push(`/Calendario/${Res.data.idcalendario}`)
        message.info('Fecha guardada');
        setStep(1);
      }
    } catch (error) {

    }
    setLoading(false);
  }

  const onMultiplicar = () => {
    setModalMultiplicar(true)
  }

  const onMultiplicada = ({ Fechas = [] }) => {
    setModalMultiplicar(false);
    message.info(`Se multiplicó la fecha (${Fechas.length} veces)`);
  }

  return (
    <div>
      <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
        <h1>FECHA</h1>
        <div>
          {
            RouteParams.id &&
            <Button onClick={onMultiplicar}>Multiplicar</Button>
          }
        </div>
      </div>
      <Steps
        // progressDot
        type='navigation'
        current={Step}
        onChange={stepChange}
      >
        <Steps.Step icon={<ReconciliationOutlined />} title="Generales" description="Datos generales del evento" />
        <Steps.Step icon={<ScheduleOutlined />} title="Evento" description="Datos del evento" />
        <Steps.Step icon={<IdcardOutlined />} title="Serie" description="Evento serie" />
        <Steps.Step icon={<WalletOutlined />} title="Precios" description="Precios y formas de pago" />
        <Steps.Step icon={<GlobalOutlined />} title="Internet" description="Información de venta por internet" />
      </Steps>

      <div style={{ padding: 15, backgroundColor: 'transparent' }}>
        {
          {
            0: <Generales fill={Fill} refill={fillForm} data={Data} onNext={(data) => onNext(data, 0)} />,
            1: <Evento fill={Fill} refill={fillForm} data={Data} onNext={(data) => onNext(data, 1)} />,
            2: <Serie fill={Fill} data={Data} onNext={(data) => onNext(data, 2)} />,
            3: <Pagos fill={Fill} data={Data} onNext={(data) => onNext(data, 3)} />,
            4: <Internet fill={Fill} data={Data} onNext={(data) => onNext(data, 4)} loading={Loading} />
          }[Step]
        }
      </div>

      <div>
        <Modal
          visible={ModalMultiplicar}
          destroyOnClose={true}
          onOk={() => setModalMultiplicar(false)}
          onCancel={() => setModalMultiplicar(false)}
          width={800}
          footer={<div />}
        >
          <Multiplicar calendario={RouteParams.id} onMultiplicada={onMultiplicada} />
        </Modal>
      </div>
    </div>
  )
}
