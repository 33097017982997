import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import { message } from 'antd';

export default function CambioPropietario() {
  
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('selectsearch', 'idpropietario', 'Nuevo propietario', 12, { required: true }),
    ], ['Cambiar'], null, false)
  );
  
  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, []);

  const fillForm = async () => {
    try {
      let Res = await Http.get(`propietario/all`);
      // console.log(Res.data)
      if(!Res.error) {
        setFormData({...Form.FieldOptions({...FormData}, 'idpropietario', Res.data, 'idpropietario', 'descripcion')})
      }
    } catch (error) {
      
    }
  }
  
  const onSubmit = async (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    try {
      let Res = await Http.post(`nprop`, {...Values});
      console.log(Res);
      if(!Res.error) {
        localStorage.setItem('bt', Res.data.token);
        message.info(`Se cambió el propietario a ${Values.idpropietario}`);
        window.location.reload();
      }
    } catch (error) {
      
    }
  }
  
  return (
    <div>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
