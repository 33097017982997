import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Propietarios() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('descripcion', 'Nombre'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('propietario', 'descripcion', 'idpropietario')
    )
  )

  return (
    <div>
      <h2>Propietarios</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}


