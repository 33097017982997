import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Form, FormComponent } from 'components/Form'
import { useHistory } from "react-router-dom";
import Http from 'Http';
import { Button, Modal, Spin, message } from 'antd';
import Area from '../Areas/Area';
import './Recintos.css';
import Theme from 'Theme';
import { CloseCircleFilled } from '@ant-design/icons';
import BackButton from 'components/BackButton/BackButton';

const Params = []
export default function Recinto({ onSave = null, idpropietario = null, goBack = true }) {
  const History = useHistory();
  const RouteParams = useParams();
  const [Areas, setAreas] = useState([])
  const [AreasLoading, setAreasLoading] = useState(false)
  const [ModalArea, setModalArea] = useState({ open: false, selected: null })
  const [CopiarLoading, setCopiarLoading] = useState(false)
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('idrecinto'),
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 4, { required: true }),
      Form.Field('selectsearchm', 'vendidopor', 'Vendido por', 4, { required: true }),
      Form.Field('select', 'clavecorta', 'Usuario alta', 4, { required: true }, [], undefined, null, true),
      Form.Field('text', 'nombre', 'Nombre', 6, { required: true, maxLength: 40 }),
      Form.Field('text', 'telefono', 'Teléfono', 3, { required: false }),
      Form.Field('text', 'fax', 'Fax', 3, { required: false }),
      Form.Field('selectsearch', 'ciudad', 'Ciudad', 3, { required: true }),
      Form.Field('text', 'direccion', 'Dirección', 9, { required: false, maxLength: 60 }),
      Form.Field('text', 'contacto', 'Contacto', 6, { required: false, maxLength: 40 }),
      Form.Field('text', 'email', 'Email', 6, { required: false, maxLength: 40 }),
      Form.Field('text', 'paginaweb', 'Página web', 12, { required: false, maxLength: 250 }),
      Form.Field('checkbox', 'activo', 'Activo', 12, { required: false }),
      Form.Field('text', 'viasdeacceso', 'Vías de acceso', 6, { required: false, maxLength: 250 }),
      Form.Field('text', 'estacionamiento', 'Estacionamiento', 6, { required: false, maxLength: 250 }),
      Form.Field('text', 'ninos', 'Niños', 6, { required: false, maxLength: 250 }),
      Form.Field('text', 'areadiscapacitados', 'Área discapacitados', 6, { required: false, maxLength: 250 }),
      Form.Field('text', 'restricciones', 'Restricciones', 6, { required: false, maxLength: 250 }),
      Form.Field('text', 'informacionadd', 'Información adicional', 6, { required: false, maxLength: 250 }),
      Form.Field('selectsearch', 'idrecintop', 'Recinto padre', 12, { required: false }),
    ], ['Guardar'], 'recinto', false)
  );

  useEffect(() => {
    if (idpropietario) {
      setFormData(Form.Patch({ ...FormData }, { idpropietario }))
    }
    fillForm();
    cargarAreas();
    // eslint-disable-next-line
  }, []);

  const cargarAreas = async () => {
    const { id: idrecinto } = RouteParams;
    if (!idrecinto) return;
    setAreasLoading(true);
    try {
      let Res = await Http.get(`recinto/${idrecinto}/areas`);
      console.log(Res);
      if (!Res.error) {
        setAreas([...Res.data]);
      }
      setAreasLoading(false);
    } catch (error) {
      console.log(error)
      setAreasLoading(false);
    }
  }

  const fillForm = async () => {
    try {
      let Res = await Http.get('recinto/form', Params);
      if (!Res.error) {
        if (Res.data.idusuario)
          setFormData(Form.Patch({ ...FormData }, { clavecorta: `${Res.data.idusuario}` }))
        setFormData(
          Form.FieldOptionsGroup({ ...FormData }, [
            Form.FOG_Field('idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'),
            Form.FOG_Field('idrecintop', Res.data.recintos, 'idrecinto', 'nombre'),
            Form.FOG_Field('vendidopor', Res.data.propietarios, 'idpropietario', 'descripcion'),
            Form.FOG_Field('ciudad', Res.data.ciudades, 'ciudad', 'ciudad'),
            Form.FOG_Field('clavecorta', Res.data.usuarios, 'clavecorta', 'usuario'),
          ])
        )

      }
    } catch (error) {

    }
  }

  const onSubmit = async (Values, Button) => {
    Values.id = RouteParams.id || null;
    console.log(`[Submit] Botón ${Button}, valores: `, Values, Areas)

    try {
      let Res = await Http.post('recinto/save/areas', { recinto: Values, areas: Areas });
      console.log(Res)
      if (!Res.error) {
        // History.push(`${Res.data.idrecinto}`)
        if (onSave) {
          onSave(true, Res.data);
          return;
        }
        History.goBack()
      }
    } catch (error) {
      if (onSave)
        onSave(false, {});
      console.log(error)
    }

  }

  const addArea = () => {
    setModalArea({ selected: null, open: true })
  }
  const onAddArea = (saved, data) => {
    console.log(saved, data);
    if (saved) {
      if (data.ai !== undefined && data.ai !== null)
        Areas[data.ai] = data;
      else
        Areas.push(data);
      setModalArea({ selected: null, open: false })
    }

  }
  const onClickArea = (area, ai) => {
    setModalArea({ selected: { ...area, ai }, open: true })
  }
  const removeArea = (Area, Ai) => {
    console.log('Remove Area', Ai, Areas)
    Areas[Ai].deleted = true;
    // console.log(Areas)
    setAreas([...Areas]);
  }

  const confirmCopy = async () => {
    Modal.confirm({
      centered: true,
      title: 'Confirmar',
      content: '¿Copiar el recinto?',
      okText: 'Sí, copiar',
      cancelText: 'No copiar',
      onCancel: () => { },
      onOk: async () => { await copiarRecinto() }
    })
  }

  const copiarRecinto = async () => {
    if (!RouteParams.id || isNaN(RouteParams.id)) return message.error('Entrar al recinto');
    setCopiarLoading(true)
    try {
      let Res = await Http.post(`recinto/copiar/${RouteParams.id}`, {});
      console.log(Res);
      if (!Res.error) {
        History.push(`/Catalogo/Recinto/${Res.data.idrecinto}`)
        message.info('Recinto copiado');
      }
      else {
        message.error('Error al copiar')
      }
      setCopiarLoading(false)
    } catch (error) {
      setCopiarLoading(false)

    }
  }

  return (
    <div>
      {goBack && <BackButton />}
      <h2>Recinto</h2>
      {
        RouteParams.id && (
          <div style={{ textAlign: 'right', paddingBottom: 10 }}>
            <Button icon='copy' shape='circle' onClick={() => confirmCopy()} loading={CopiarLoading} />
          </div>
        )
      }
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
        onSave={onSave}
      >
        <div style={{}}>
          <div style={{ textAlign: 'right' }}>
            <Button icon='plus-circle' shape='round' onClick={addArea}>Área</Button>
          </div>
          <div className='areas-contenedor' style={{ borderColor: Theme.primary }}>
            <h4>Áreas</h4>
            {
              Areas.length === 0 && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  {AreasLoading && <span><Spin /></span>}
                  {!AreasLoading && <span>No hay áreas</span>}
                </div>
              )
            }
            {Areas.map((Area, Ai) => (!Area.deleted &&
              <div key={`${Area.area}-${Ai}` || Ai} className='areas-prearea'>
                <div className='areas-remove'>
                  <CloseCircleFilled onClick={(e) => removeArea(Area, Ai)} />
                </div>
                <div className='areas-area' onClick={() => onClickArea(Area, Ai)}>
                  {Area.area || 'ÁREA NOMBRE'}
                </div>
              </div>
            ))}
          </div>
        </div>
      </FormComponent>

      <Modal
        title="Agregar área"
        visible={ModalArea.open}
        onOk={() => setModalArea({ ...ModalArea, open: false })}
        onCancel={() => setModalArea({ ...ModalArea, open: false })}
        footer={<div />}
        width='90%'
        destroyOnClose={true}
      >
        <Area onSave={onAddArea} idrecinto={RouteParams.id || 'Creando (no modificar)'} save={false} area={ModalArea.selected} adding={ModalArea.selected ? false : true} loadAreas={cargarAreas} />
      </Modal>

    </div>
  )
}
