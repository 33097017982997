import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';

export default function Proceso() {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 6, { required: true }),
      Form.Field('select', 'sistema', 'Sistema', 6, { required: true }),
      Form.Field('select', 'modulo', 'Módulo', 8, { required: true }),
      Form.Field('number', 'nivel', 'Nivel', 4, { required: true }),
      Form.Field('text', 'descripcion', 'Descripción', 12, { required: true }),
    ], ['Guardar'], 'proceso')
  );

  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, []);

  const fillForm = async () => {
    try {
      let Res = await Http.get('proceso/form');
      if (!Res.error) {
        setFormData(
          Form.FieldOptionsGroup({ ...FormData }, [
            Form.FOG_Field('idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'),
            Form.FOG_Field('sistema', Res.data.sistemas, 'value', 'label'),
            Form.FOG_Field('modulo', Res.data.modulos, 'value', 'label'),
          ])
        )
      }
    } catch (error) {

    }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
  }

  return (
    <div>
      <h2>Proceso</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
