import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import moment from 'moment';
import Global from 'Global';

export default function Internet({ onNext, data = {}, fill = {}, loading = false }) {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('checkbox', 'pinternet', 'Publicar', 6, { required: false }),
      Form.Field('checkbox', 'vinternet', 'Vender', 6, { required: false }, null, undefined, (val, cf) => venderChange(val, cf)),
      Form.Field('radio', 'estado_noventa', 'Estado', 12, { required: false }, [
        { Value: 0, Label: 'No disponible' },
        { Value: 1, Label: 'Proximamente' },
        { Value: 2, Label: 'Bol. x taquilla' },
        { Value: 3, Label: 'Agotado' },
        { Value: 4, Label: 'Cerrado' },
      ], undefined, null, false),
      Form.Field('number', 'mincantventa', 'Cantidad mínima', 6, { required: true }, null, 1),
      Form.Field('number', 'maxcantventa', 'Cantidad máxima', 6, { required: true }, null, 10),
      Form.Field('datetime', 'fec_ini_vta_web', 'Vigencia inicio', 6, { required: true }, null, moment().hours(0).minutes(0)),
      Form.Field('datetime', 'fec_fin_vta_web', 'Vigencia fin', 6, { required: true }, null, moment().add(1, 'd').hours(23).minutes(59)),
    ], ['Guardar'], null, false)
  );

  useEffect(() => {
    if (data) {
      // console.log('data', data)
      setFormData(Form.Patch({ ...FormData }, { ...data }))
    }
    propSettings();
    // eslint-disable-next-line
  }, [fill]);

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({
        ...Form.FieldModGroup(FormData, [
          Form.FMG_Field("pinternet", "Disabled", true),
          Form.FMG_Field("vinternet", "Disabled", true),
          Form.FMG_Field("estado_noventa", "Disabled", true),
          Form.FMG_Field("mincantventa", "Disabled", true),
          Form.FMG_Field("maxcantventa", "Disabled", true),
          Form.FMG_Field("fec_ini_vta_web", "Disabled", true),
          Form.FMG_Field("fec_fin_vta_web", "Disabled", true),
        ])
      });
    }
  }

  const venderChange = (val, cf) => {
    // console.log(cf)
    setFormData({ ...Form.FieldMod({...FormData, Fields: cf}, 'estado_noventa', 'Disabled', val) })
    //   if(val) {
    // }
    // else {

    // }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    onNext(Values)
  }

  return (
    <div>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
        loading={loading}
      />
    </div>
  )
}
