import styled from 'styled-components';


export const SlideStyled = styled.div`
.side ::-webkit-scrollbar-track {
    /* background: #ffedd7; */
    background: #d6d6d6;
    border-radius: 5px
}

.side ::-webkit-scrollbar-thumb {
    /* background: #e37b00; */
    background: #${props => props.primaryColor};
    border-radius: 5px
}
`