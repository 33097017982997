import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Ciudades() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('nombrecorto', 'Nombre corto'),
      Table.Field('ciudad', 'Ciudad'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('ciudad', 'ciudad', 'idciudad')
    )
  )
  
  return (
    <div>
      <h2>Ciudades</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
