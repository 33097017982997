import React, { useState, useEffect, useContext } from 'react'
import './Inicio.css';
import { Icon, Menu, Button, Tooltip, Modal } from 'antd';
import Theme from '../../Theme';
import SubMenu from 'antd/lib/menu/SubMenu';
import Options from './SideItems';
import { useHistory } from "react-router-dom";
// import { AppContext } from 'App';
import Permitido from 'Procesos';
import { AppContext } from 'App';
import CambioPropietario from './CambioPropietario';
import { SlideStyled } from './Slide.styled';
// import Http from 'Http';

export default function Side({ onLogOut }) {
  const [Open, setOpen] = useState(false)
  const [PropModal, setPropModal] = useState(false);
  let history = useHistory();
  const AppValues = useContext(AppContext)
  // const AppValues = useContext(AppContext);
  // console.log(value)

  useEffect(() => {
    loadProcesos();
  }, [])

  const loadProcesos = async () => {
    try {
      // let Res = await Http.get('proceso/all')
    } catch (error) {

    }
  }

  const menuClick = (e) => {
    // console.log(e)
    history.push(e.item.props.link);
  }

  const renderSubMenu = (items, id) => {
    // console.log(items)
    let menu = items.map((item, ii) => {
      // console.log(`Se verifica para el Side si se permite ${item.model}`)
      if (item.model && !Permitido.Ver(AppValues, item.model)) return null;
      if (item.type === 'link')
        return (<Menu.Item key={`${id}-${ii}`} link={item.link}>{item.label}</Menu.Item>)
      else if (item.type === 'children')
        return (
          <SubMenu key={`${id}-${ii}`} title={item.label}>
            {renderSubMenu(item.children, item.label)}
          </SubMenu>
        )
      return null;
    })

    return menu;
  }

  const cambiarProp = () => {
    setPropModal(true)
  }

  return (
    <SlideStyled primaryColor={AppValues.prop.color1}>
      <div className='side'>
        <div className={`bg ${Open ? 'open' : ''}`} onClick={() => setOpen(!Open)}></div>
        <div className={`side ${Open ? 'open' : ''}`}>
          <div className='logo'>
            {AppValues.prop.logo_url && <img src={AppValues.prop.logo_url} alt='Logo' />}
          </div>
          <div className='nombre'>
            {AppValues.prop.nombre_sistema && <h2>{AppValues.prop.nombre_sistema}</h2>}
          </div>
          <Menu
            onClick={menuClick}
            style={{ width: 195 }}
            defaultSelectedKeys={[]}
            defaultOpenKeys={['Sub1-3']}
            mode="inline"
          >
            {
              renderSubMenu(Options, 'Sub1')
            }
          </Menu>

          <div style={{ textAlign: 'right', paddingRight: 20, paddingBottom: 60 }} className='buttons'>
            <Tooltip title='Cambiar propietario'>
              <Button type='primary' icon='swap' onClick={() => cambiarProp()} shape='circle' />
            </Tooltip>
            <Tooltip title='Cerrar sesión'>
              <Button type='primary' icon="logout" onClick={() => { localStorage.removeItem('bt'); history.push('/'); onLogOut() }} shape='circle' />
            </Tooltip>
          </div>
        </div>
        <div className={`icon ${Open ? 'open' : ''}`}>
          {!Open && <Icon type="menu" style={{ fontSize: 40, color: Theme.primary, cursor: 'pointer' }} onClick={() => setOpen(!Open)} />}
          {Open && <Icon type="close" className={'icon-close'} style={{ fontSize: 40, color: Theme.primary, cursor: 'pointer' }} onClick={() => setOpen(!Open)} />}
        </div>

        <div>
          <Modal
            title='Cambiar propietario'
            visible={PropModal}
            footer={[
              <Button key="back" onClick={() => setPropModal(false)}>
                Cerrar
            </Button>
            ]}
            onOk={() => setPropModal(false)}
            onCancel={() => setPropModal(false)}
            destroyOnClose={true}
          >
            <CambioPropietario />
          </Modal>
        </div>
      </div>
    </SlideStyled>
  )
}
