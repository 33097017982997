import React from 'react'
import {
  Switch,
  Route,
} from "react-router-dom";
import RecursosTable from './Recursos';
import Recurso from './Recurso';
import Disponibilidades from './Disponibilidad/Disponibilidades';
import Disponibilidad from './Disponibilidad/Disponibilidad';

export default function Recursos() {
  return (
    <div>
        <Switch>
          <Route path="/Recursos/Recursos">
            <RecursosTable />
          </Route>
          <Route path="/Recursos/Recurso/:id?">
            <Recurso />
          </Route>
          <Route path="/Recursos/Disponibilidades/">
            <Disponibilidades />
          </Route>
          <Route path="/Recursos/Recurso-Disponibilidad/:id?">
            <Disponibilidad />
          </Route>
        </Switch>
    </div>
  )
}
