import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'
import Permitido from 'Procesos'

export default function Productos() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('idpropietario', 'Propietario'),
      Table.Field('producto', 'Producto'),
      Table.Field('descrip', 'Descripción'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('producto', 'producto', 'idproducto')
    )
  )
  
  if(!Permitido.Ver('Producto')) return Permitido.No();
  return (
    <div>
      <h2>Productos</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
