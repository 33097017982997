import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import BackButton from 'components/BackButton/BackButton';

export default function FormaEnvio() {
  
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('IDENVIO'),
      Form.Field('text', 'DESCRIPCION', 'Descripción', 8, { required: true }),
      Form.Field('number', 'COSTOENVIO', 'Costo de envío', 4, { required: true }),
      Form.Field('select', 'idtipo', 'Tipo de envío', 12, { required: true }),
    ], ['Guardar'], 'forma-envio')
  );

  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, []);

  const fillForm = async () => {
    try {
      let Res = await Http.get('forma-envio/form');
      if (!Res.error) {
        setFormData(Form.FieldOptions({ ...FormData }, 'idtipo', Res.data.tipos_envio, 'idtipo', 'descripcion'))
      }
    } catch (error) {

    }
  }
  
  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
  }
  
  return (
    <div>
      <BackButton />
      <h2>Forma de Envío</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
