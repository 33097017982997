import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Global from 'Global';
// import Http from 'Http';

let Params = []
export default function Generales({ onNext, data = {}, fill = null, refill }) {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: true }, [], undefined, (val) => PropietarioChange(val)),
      Form.Field('autocomplete', 'idproductor', 'Productor', 12, { required: true }, [], undefined, null, false, (val) => AC_Fetch(val)),
      Form.Field('selectsearchm', 'vendidopor', 'Vendido por', 12, { required: true }),
      Form.Field('select', 'clavecorta', 'Usuario alta', 12, { required: true }, [], undefined, null, true),
    ], ['Siguiente'], null, false)
  );

  useEffect(() => {
    if (data) {
      // console.log('data', data)
      setFormData(Form.Patch({ ...FormData }, { ...data }))
    }
    if (fill) {
      // console.log('fill', fill)
      if (fill.idusuario)
        setFormData(Form.Patch({ ...FormData }, {
          clavecorta: `${fill.idusuario}`,
        }))
      setFormData(
        Form.FieldOptionsGroup({ ...FormData }, [
          Form.FOG_Field('idpropietario', fill.propietarios, 'idpropietario', 'descripcion'),
          Form.FOG_Field('idproductor', fill.productores, 'idproductor', 'nombre'),
          Form.FOG_Field('vendidopor', fill.propietarios, 'idpropietario', 'descripcion'),
          Form.FOG_Field('clavecorta', fill.usuarios, 'clavecorta', 'usuario'),
        ])
      )
    }
    propSettings();
    // eslint-disable-next-line
  }, [fill]);

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({
        ...Form.FieldModGroup(FormData, [
          Form.FMG_Field("idpropietario", "Value", Global.PROP),
          Form.FMG_Field("idproductor", "Value", 1), // Cambiar x productos BPMEX
          Form.FMG_Field("vendidopor", "Value", [Global.PROP, "AREMA"]),
        ])
      });
    }
  }



  const PropietarioChange = (val) => {
    console.log(val)
    Params = Form.FillParam(Params, 'prop', val);
    refill(Params)
  }

  const AC_Fetch = (value) => {
    // console.log(value)
    Params = Form.FillParam(Params, 'prod', value);
    refill(Params)
    // fillForm();
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)

    onNext(Values)
  }

  return (
    <div>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
